import { listConcepts } from "./../graphql/queries";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setBranches,
  setListing,
  setSelected,
  setFilters,
  setSelectedFilters,
  setNextToken,
} from "../store/ducks/concept";
import { Concept } from "../models";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import {
  ConceptBulkTrashVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
} from "../models/app";
import { getConcept } from "../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { LOCAL_STORAGE } from "../constants/enums";
import {
  createConcept,
  deleteConcept,
  updateConcept,
} from "../graphql/mutations";
import { onCreateConcept } from "../graphql/subscriptions";
import { CreateConceptInput, UpdateConceptInput } from "../models/GQL_API";
import encryptData from "../services/encryption";
import {
  extractSelectedCheckboxes,
  getChangedStringData,
} from "../helpers/utils";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );
  const conceptsListing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const parentConceptsSelected = useSelector(
    (state: any) => state.parentconcepts.selected
  );
  async function fetch(params: ConceptListingVariables) {
    const { accountID, limit } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
        accountID: { eq: accountID },
      };

      if (parentConceptsSelected) {
        filter.parentConceptID = { eq: parentConceptsSelected };
      }

      const dataList: any = await API.graphql({
        query: listConcepts,
        variables: {
          filter,
          limit,
          nextToken,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = dataList.data.listConcepts.nextToken;
      const responseListing = dataList.data.listConcepts.items;

      let listing = [...conceptsListing, ...responseListing];
      // console.log({ listing });
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchAll(params: ConceptListingVariables) {
    const { accountID } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
        accountID: { eq: accountID },
      };

      let allItems: any[] = [];
      let nextToken: string | null = null;

      do {
        const dataList: any = await API.graphql({
          query: listConcepts,
          variables: {
            filter,
            nextToken: nextToken,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        const currentNextToken = dataList.data.listConcepts.nextToken;
        const responseListing = dataList.data.listConcepts.items;

        allItems = [...allItems, ...responseListing];
        nextToken = currentNextToken;
      } while (nextToken);

      return allItems;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAllOnline() {
    const filter: any = {
      deleted: { eq: "0" },
    };

    try {
      const listing: any = await API.graphql({
        query: listConcepts,
        variables: { filter, limit: 100 },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      return listing.data.listConcepts.items;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;

    try {
      let single: Concept | undefined;
      if (listing.length > 0) {
        single = listing.find((resource: any) => resource.id === id);
      } else {
        const listing: any = await API.graphql<Concept>({
          query: getConcept,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getConcept;
      }
      // console.log({ vat: single ? single.vatPercentage : "", single });
      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string, isAuth: boolean) {
    try {
      const user: any = await API.graphql({
        query: getConcept,
        variables: { id: id },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return user.data.getConcept;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const seats = [
        data.seatTypes_Bar ? "Bar" : null,
        data.seatTypes_Table ? "Table" : null,
      ];
      const areas = [
        data.areas_Indoor ? "Indoor" : null,
        data.areas_Outdoor ? "Outdoor" : null,
      ];

      const categoryTagIds: string[] = extractSelectedCheckboxes(
        "categoryTagIDs_",
        data
      );

      const createInput: CreateConceptInput = {
        accountID: data.accountID,
        name: data.name,
        openTime: data.openTime ? data.openTime : null,
        closeTime: data.closeTime ? data.closeTime : null,
        description: data.description ? data.description : "",
        location: data.location ? data.location : "",
        type: data.type ? data.type : "",
        seatTypes: seats ? seats : [],
        categoryTagIDs: categoryTagIds ?? [],
        areas: areas ? areas : [],
        sliderImages: data.sliderImages ? data.sliderImages : [],
        logo: data.logo ? data.logo : "",
        album: data.album ? data.album : [],
        merchantID: data.merchantID ? data.merchantID : "",
        merchantUsername: data.merchantUsername ? data.merchantUsername : "",
        merchantPassword: data.merchantPassword ? data.merchantPassword : "",
        isPinRequired: data.isPinRequired ? data.isPinRequired : false,
        needAssistance: data.needAssistance ? data.needAssistance : false,
        spaBooking: data.spaBooking ? data.spaBooking : false,
        showerBooking: data.showerBooking ? data.showerBooking : false,
        cigarMenu: data.cigarMenu ? data.cigarMenu : false,
        viewOnly: data.viewOnly ? data.viewOnly : false,
        varifyForAmount: data.varifyForAmount ? data.varifyForAmount : 1000,
        varifyFirstOrder: data.varifyFirstOrder ? data.varifyFirstOrder : false,
        varifyNewAddress: data.varifyNewAddress ? data.varifyNewAddress : false,
        receiveWaiters: [],
        WaiterNotificationOption: "",
        precedence: data.precedence ? parseInt(data.precedence) : 1,
        rewardPoints: data.rewardPoints ? data.rewardPoints : 0,
        // moneySpent: data.moneySpent ? data.moneySpent : 0,
        moneySpent: 1,
        afterVat: data.afterVat ? data.afterVat : false,
        afterDeliveryFee: data.afterDeliveryFee ? data.afterDeliveryFee : false,
        beforePromoCode: data.beforePromoCode ? data.beforePromoCode : false,
        pointsCalcWithPromoCode: data.pointsCalcWithPromoCode
          ? data.pointsCalcWithPromoCode
          : false,
        isBusy: data.isBusy ? data.isBusy : false,
        allowOnlineOrdering: data.allowOnlineOrdering
          ? data.allowOnlineOrdering
          : false,
        minOrderVal: data.minOrderVal ? data.minOrderVal : 0,

        // Simphony Configuration
        simphonyServerBaseURL: data.simphonyServerBaseURL
          ? data.simphonyServerBaseURL
          : "",
        simphonyLocRef: data.simphonyLocRef ? data.simphonyLocRef : "",
        simphonyShortOrgName: data.simphonyShortOrgName
          ? data.simphonyShortOrgName
          : "",
        vatPercentage: data.vatPercentage
          ? (parseFloat(data.vatPercentage) + 100) / 100
          : 0,
        serviceChargePercentage: data.serviceChargePercentage
          ? (parseFloat(data.serviceChargePercentage) + 100) / 100
          : 0,
        addDeliveryToVat: data.addDeliveryToVat ?? false,
        revenueCenterID: data.revenueCenterID ? data.revenueCenterID : null,
        simphonyTakeAwayRvc: data.simphonyTakeAwayRvc
          ? data.simphonyTakeAwayRvc
          : null,
        simphonyDeliveryRvc: data.simphonyDeliveryRvc
          ? data.simphonyDeliveryRvc
          : null,
        employeeID: data.employeeID ? data.employeeID : null,
        tenderID: data.tenderID ? parseInt(data.tenderID) : null,

        useServiceCharge: data.useServiceCharge ? data.useServiceCharge : false,
        serviceChargeId: data.serviceChargeId
          ? parseInt(data.serviceChargeId)
          : null,
        //orderTypes
        orderTypeDelivery: data.orderTypeDelivery
          ? parseInt(data.orderTypeDelivery)
          : null,
        parentConceptID: data.parentConcept ? data.parentConcept : null,
        orderTypeDineIn: data.orderTypeDineIn
          ? parseInt(data.orderTypeDineIn)
          : null,
        orderTypeTakeAway: data.orderTypeTakeAway
          ? parseInt(data.orderTypeTakeAway)
          : null,
        avgOrderCompletionEstimate: data.avgOrderCompletionEstimate ?? "",
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
        // ...encObj,
      };

      const createdConcept = await API.graphql<Concept>({
        query: createConcept,
        variables: { input: createInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return createdConcept;
    } catch (err) {
      console.log({ err });
      showError(err);
    }
  }

  async function update(params: any) {
    const { id, listing, data, newReceiveWaiter, delteWaiterID } = params;
    console.log({ data, vat: data.containerColor });

    try {
      const original: any = await get({ id, listing });
      // console.log({ data, original });
      // throw "";

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const categoryTagIds: string[] = extractSelectedCheckboxes(
        "categoryTagIDs_",
        data
      );

      console.log({ categoryTagIds });

      // let merchantIntegrationNumber, merchantAPIKey;
      // let merchantIntegrationNumberChanged = false;
      // let merchantAPIKeyChanged = false;
      // if (
      //   original.merchantIntegrationNumber === data.merchantIntegrationNumber &&
      //   data.merchantIntegrationNumber
      // ) {
      //   merchantIntegrationNumber = original.merchantIntegrationNumber;
      // } else {
      //   merchantIntegrationNumberChanged = true;
      //   merchantIntegrationNumber = data.merchantIntegrationNumber;
      // }
      // if (
      //   original.merchantAPIKey === data.merchantAPIKey &&
      //   data.merchantAPIKey
      // ) {
      //   merchantAPIKey = original.merchantAPIKey;
      // } else {
      //   merchantAPIKeyChanged = true;
      //   merchantAPIKey = data.merchantAPIKey;
      // }
      // const dataToEncrypt = [];
      // if (merchantIntegrationNumber && merchantIntegrationNumberChanged) {
      //   dataToEncrypt.push(merchantIntegrationNumber);
      // }
      // if (merchantAPIKey && merchantAPIKeyChanged) {
      //   dataToEncrypt.push(merchantAPIKey);
      // }
      // const encryptedData = await encryptData(dataToEncrypt);
      // if (merchantIntegrationNumber && merchantIntegrationNumberChanged) {
      //   merchantIntegrationNumber = encryptedData.shift();
      // }
      // if (merchantAPIKey && merchantAPIKeyChanged) {
      //   merchantAPIKey = encryptedData.shift();
      // }

      const allChangedData = getChangedStringData(
        {
          merchantAPIKey: original.merchantAPIKey,
          merchantIntegrationNumber: original.merchantIntegrationNumber,
          // simphonyServerBaseURL: original.simphonyServerBaseURL,
          simphonyToken: original.simphonyToken,
          simphonyAuthClientId: original.simphonyAuthClientId,
          simphonyAuthUsername: original.simphonyAuthUsername,
          simphonyAuthPassword: original.simphonyAuthPassword,
        },
        {
          merchantAPIKey: data.merchantAPIKey || "",
          merchantIntegrationNumber: data.merchantIntegrationNumber || "",
          // simphonyServerBaseURL: data.simphonyServerBaseURL || "",
          simphonyToken: data.simphonyToken || "",
          simphonyAuthClientId: data.simphonyAuthClientId,
          simphonyAuthUsername: data.simphonyAuthUsername,
          simphonyAuthPassword: data.simphonyAuthPassword,
        }
      );
      //get items with new values equal to empty string
      const emptyChangedData = allChangedData.newEmpty;
      //get items with new values not equal to empty string to encrypt
      const toBeEncryptedObj = allChangedData.newFilled;
      const dataToEncrypt = Object.values<string>(toBeEncryptedObj);
      let encryptedData: string[] = [];
      if (dataToEncrypt.length > 0) {
        encryptedData = await encryptData(dataToEncrypt);
      }
      //refill the object with encrypted values
      Object.keys(toBeEncryptedObj).forEach((key, index) => {
        toBeEncryptedObj[key as keyof typeof toBeEncryptedObj] =
          encryptedData[index] ?? "";
      });

      if (!newReceiveWaiter && !delteWaiterID) {
        const updateInput: UpdateConceptInput = {
          id: original.id,
          name: data.name ? data.name : original!.name,
          openTime: data.openTime ? data.openTime : original.openTime,
          closeTime: data.closeTime ? data.closeTime : original.closeTime,
          description: data.description
            ? data.description
            : original!.description,
          merchantID: data.merchantID,
          image: data.image ? data.image : original.image,
          merchantUsername: data.merchantUsername,
          merchantPassword: data.merchantPassword
            ? data.merchantPassword
            : original!.merchantPassword,
          sliderImages: data.sliderImages
            ? data.sliderImages
            : original!.sliderImages,
          categoryTagIDs: categoryTagIds
            ? categoryTagIds
            : original.categoryTagIDs,
          precedence: parseInt(data.precedence),
          isPinRequired: data.isPinRequired,
          needAssistance: data.needAssistance,
          spaBooking: data.spaBooking,
          showerBooking: data.showerBooking,
          cigarMenu: data.cigarMenu,
          viewOnly: data.viewOnly,
          WaiterNotificationOption: data.WaiterNotificationOption
            ? data.WaiterNotificationOption
            : original!.WaiterNotificationOption,
          varifyForAmount: data.varifyForAmount
            ? data.varifyForAmount
            : original!.varifyForAmount,
          varifyFirstOrder: data.varifyFirstOrder
            ? data.varifyFirstOrder
            : original!.varifyFirstOrder,
          varifyNewAddress: data.varifyNewAddress
            ? data.varifyNewAddress
            : original!.varifyNewAddress,
          simphonyAuthBaseURL: data.simphonyAuthBaseURL
            ? data.simphonyAuthBaseURL
            : original!.simphonyAuthBaseURL,

          // Simphony Configuration
          // simphonyServerBaseURL: data.simphonyServerBaseURL
          //   ? data.simphonyServerBaseURL
          //   : "",
          containerColor: data.containerColor
            ? data.containerColor
            : original.containerColor,
          simphonyLocRef: data.simphonyLocRef
            ? data.simphonyLocRef
            : original!.simphonyLocRef,
          simphonyShortOrgName: data.simphonyShortOrgName
            ? data.simphonyShortOrgName
            : original!.simphonyShortOrgName,
          revenueCenterID: data.revenueCenterID
            ? data.revenueCenterID
            : original.revenueCenterID,
          simphonyTakeAwayRvc: data.simphonyTakeAwayRvc
            ? data.simphonyTakeAwayRvc
            : original.simphonyTakeAwayRvc,
          simphonyDeliveryRvc: data.simphonyDeliveryRvc
            ? data.simphonyDeliveryRvc
            : original.simphonyDeliveryRvc,
          employeeID: data.employeeID ? data.employeeID : original.employeeID,
          tenderID: data.tenderID ? parseInt(data.tenderID) : original.tenderID,
          discountID: data.discountID
            ? parseInt(data.discountID)
            : original.discountID,

          useServiceCharge: data.useServiceCharge
            ? data.useServiceCharge
            : false,
          serviceChargeId: data.serviceChargeId
            ? parseInt(data.serviceChargeId)
            : original.serviceChargeId,
          //order Types
          orderTypeDelivery: data.orderTypeDelivery
            ? parseInt(data.orderTypeDelivery)
            : original.orderTypeDelivery,

          orderTypeDineIn: data.orderTypeDineIn
            ? parseInt(data.orderTypeDineIn)
            : original.orderTypeDineIn,
          orderTypeTakeAway: data.orderTypeTakeAway
            ? parseInt(data.orderTypeTakeAway)
            : original.orderTypeTakeAway,
          rewardPoints:
            data.rewardPoints !== null && data.rewardPoints !== undefined
              ? data.rewardPoints
              : original.rewardPoints,
          moneySpent:
            data.moneySpent !== null && data.moneySpent !== undefined
              ? data.moneySpent
              : original.moneySpent,
          afterVat: data.afterVat,
          afterDeliveryFee: data.afterDeliveryFee,
          beforePromoCode: data.beforePromoCode,
          pointsCalcWithPromoCode: data.pointsCalcWithPromoCode,
          isBusy: data.isBusy,
          allowOnlineOrdering: data.allowOnlineOrdering,
          minOrderVal: data.minOrderVal ? data.minOrderVal : 0,
          vatPercentage: data.vatPercentage
            ? (parseFloat(data.vatPercentage) + 100) / 100
            : original.vatPercentage,
          serviceChargePercentage: data.serviceChargePercentage
            ? (parseFloat(data.serviceChargePercentage) + 100) / 100
            : original.serviceChargePercentage,
          addDeliveryToVat:
            data.addDeliveryToVat ??
            (original.addDeliveryToVat ? original.addDeliveryToVat : false),
          simphonyServerBaseURL: data.simphonyServerBaseURL
            ? data.simphonyServerBaseURL
            : original!.simphonyServerBaseURL,
          addressID: data.addressID ? data.addressID : original.addressID,
          specialRequestID: data.specialRequestID
            ? data.specialRequestID
            : original.specialRequestID,
          avgOrderCompletionEstimate: data.avgOrderCompletionEstimate
            ? data.avgOrderCompletionEstimate
            : original.avgOrderCompletionEstimate,

          // spread the changed objects
          ...emptyChangedData,
          ...toBeEncryptedObj,

          // version
          _version: original._version,
        };

        const updatedConcept: any = await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        showConfirm(`${singleName} has been updated successfullyy`);
        return updatedConcept.data.updateConcept;
      } else {
        let newReceiveWaiters: any = [];
        if (original!.receiveWaiters) {
          newReceiveWaiters = [...original!.receiveWaiters];
        }
        if (newReceiveWaiters && newReceiveWaiter) {
          newReceiveWaiters.push(newReceiveWaiter);
        }
        if (newReceiveWaiters && delteWaiterID) {
          newReceiveWaiters = newReceiveWaiters.filter((stringWaiter: any) => {
            let objectWaiter = JSON.parse(stringWaiter);
            return objectWaiter.id !== delteWaiterID;
          });
        }
        console.log({ data, dataCont: data.containerColor });
        const updateInput: UpdateConceptInput = {
          id: original.id,
          receiveWaiters: newReceiveWaiters,
          rewardPoints:
            data.rewardPoints !== null && data.rewardPoints !== undefined
              ? data.rewardPoints
              : original.rewardPoints,
          moneySpent:
            data.moneySpent !== null && data.moneySpent !== undefined
              ? data.moneySpent
              : original.moneySpent,
          categoryTagIDs: categoryTagIds
            ? categoryTagIds
            : original.categoryTagIDs,
          image: data.image ? data.image : original.image,
          afterVat: data.afterVat,
          afterDeliveryFee: data.afterDeliveryFee,
          beforePromoCode: data.beforePromoCode,
          simphonyLocRef: data.simphonyLocRef
            ? data.simphonyLocRef
            : original!.simphonyLocRef,
          simphonyShortOrgName: data.simphonyShortOrgName
            ? data.simphonyShortOrgName
            : original!.simphonyShortOrgName,
          revenueCenterID: data.revenueCenterID
            ? data.revenueCenterID
            : original.revenueCenterID,
          simphonyTakeAwayRvc: data.simphonyTakeAwayRvc
            ? data.simphonyTakeAwayRvc
            : original.simphonyTakeAwayRvc,
          simphonyDeliveryRvc: data.simphonyDeliveryRvc
            ? data.simphonyDeliveryRvc
            : original.simphonyDeliveryRvc,
          employeeID: data.employeeID ? data.employeeID : original.employeeID,
          tenderID: data.tenderID ? parseInt(data.tenderID) : original.tenderID,
          discountID: data.discountID
            ? parseInt(data.discountID)
            : original.discountID,
          vatPercentage: data.vatPercentage
            ? (parseFloat(data.vatPercentage) + 100) / 100
            : original.vatPercentage,
          serviceChargePercentage: data.serviceChargePercentage
            ? (parseFloat(data.serviceChargePercentage) + 100) / 100
            : original.serviceChargePercentage,
          addDeliveryToVat:
            data.addDeliveryToVat ??
            (original.addDeliveryToVat ? original.addDeliveryToVat : false),
          containerColor: data.containerColor
            ? data.containerColor
            : original.containerColor,
          useServiceCharge: data.useServiceCharge
            ? data.useServiceCharge
            : original!.useServiceCharge,
          serviceChargeId: data.serviceChargeId
            ? parseInt(data.serviceChargeId)
            : original.serviceChargeId,
          simphonyAuthBaseURL: data.simphonyAuthBaseURL
            ? data.simphonyAuthBaseURL
            : original!.simphonyAuthBaseURL,
          avgOrderCompletionEstimate: data.avgOrderCompletionEstimate
            ? data.avgOrderCompletionEstimate
            : original.avgOrderCompletionEstimate,

          ...emptyChangedData,
          ...toBeEncryptedObj,

          _version: original._version,
        };
        const updatedConcept: any = await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        showConfirm(`${singleName} has been updated successfully`);
        return updatedConcept.data.updateConcept;
      }
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ConceptGetVariables) {
    try {
      const { id, listing } = params;

      const original: any = await get(params);

      if (original) {
        const updateInput: UpdateConceptInput = {
          id: original.id,
          deleted: "1",
          _version: original._version,
        };

        await API.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        dispatch(
          setListing(listing.filter((resource: any) => resource.id !== id))
        );
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ConceptBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await API.graphql<Concept>({
        query: deleteConcept,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id))
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function exportAll(params: ConceptListingVariables) {
    const data = await fetch(params);

    // console.log({ data });
    return data;
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  function options(listing: Concept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },

    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = Concept as any;
  api[`${listingName}CreateSubscription`] = onCreateConcept;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchOnline`] = fetchAllOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Concept[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };

  api[`${listingName}ChangeSelected`] = (conceptID: string) => {
    dispatch(setSelected(conceptID));

    localStorage.setItem(LOCAL_STORAGE.SELECTED_CONCEPT, conceptID);
  };
  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = conceptsListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;
