import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { ModelTimelineFilterInput } from "../API";
import { BookingTimelineActions, TimelineActions } from "../constants/enums";
import {
  createTimeline,
  deleteTimeline,
  updateTimeline,
} from "../graphql/mutations";
import {
  getTimeline,
  listTimelines,
  timelineByCustomerID,
  timelineByTransactionID,
} from "../graphql/queries";
import { onCreateTimeline } from "../graphql/subscriptions";
import { mergeListOfStringsByDash } from "../helpers/utils";
import { Timeline } from "../models";
import { TimelineGetVariables, TimelineListingVariables } from "../models/app";
import { HeadCell } from "../models/dataTable";
import {
  CreateTimelineInput,
  ModelSortDirection,
  UpdateTimelineInput,
} from "../models/GQL_API";
import {
  setListing,
  setNextToken,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/timeline";
import {
  CreateOnlineVariables,
  CreateVariables,
  Option,
  TimelineBulkTrashVariables,
  TimelineUpdateVariables,
} from "./../models/app";
import useApp from "./useApp";
const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  let selectedFilters = useSelector(
    (state: any) => state.timelines.selectedFilters
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]["nextToken"]
  );

  async function fetch(props: TimelineListingVariables) {
    const { limit = 1000, bookingId, customerId, transactionId } = props;

    try {
      let requestNextToken: undefined | string = undefined;

      if (
        selectedFilters &&
        selectedFilters.toString() ===
          [limit, bookingId, customerId, transactionId].toString()
      ) {
        requestNextToken = nextToken;
      }

      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (bookingId) {
        filter.bookingId = { eq: bookingId };
      }

      if (customerId) {
        filter.customerId = { eq: customerId };
      }
      if (transactionId) {
        filter.transactionId = { eq: transactionId };
      }

      const ListData: any = await API.graphql<Timeline>({
        query: listTimelines,
        variables: { filter, limit, nextToken: requestNextToken },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken = ListData.data.listTimelines.nextToken;
      const listing = ListData.data.listTimelines.items;

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      dispatch(
        setSelectedFilters([limit, bookingId, customerId, transactionId])
      );

      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchByCustomerID(props: TimelineListingVariables) {
    const { limit, customerId, transactionId } = props;


    try {
      let requestNextToken: undefined | string = undefined;
      let allListings: any[] = [];

      if (!customerId) {
        throw new Error("Customer ID is required");
      }

      // Filter Section
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (!transactionId) {
        filter.transactionId = { attributeExists: false };
      }


      // if (
      //   selectedFilters &&
      //   selectedFilters.toString() ===
      //     [limit, customerId, transactionId].toString()
      // ) {
      //   requestNextToken = nextToken;
      // }
      let currentNextToken: string | undefined;

      do {
        const ListData: any = await API.graphql<Timeline>({
          query: timelineByCustomerID,
          variables: {
            customerId: customerId,
            filter,
            limit: 1000,
            nextToken: requestNextToken,
            sortDirection: ModelSortDirection.ASC,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        currentNextToken = ListData.data.timelineByCustomerID.nextToken;
        const listing = ListData.data.timelineByCustomerID.items;

        allListings = [...allListings, ...listing];
        requestNextToken = currentNextToken;
      } while (currentNextToken);

      dispatch(setListing(allListings));
      dispatch(setNextToken(currentNextToken));
      dispatch(setSelectedFilters([limit, customerId, transactionId]));

      return allListings;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchByTransactionID(props: TimelineListingVariables) {

    console.log("Fetch b y trx")
    const { limit, customerId, transactionId } = props;

    try {
      // Filter Section
      const filter: ModelTimelineFilterInput = {
        deleted: { eq: "0" },
      };

      if (!transactionId) {
        throw new Error("Customer ID is required");
      }
      if (customerId) {
        filter.customerId = { eq: customerId };
      }
      let currentNextToken: any;
      let listing: any = [];
      do {
        const ListData: any = await API.graphql<Timeline>({
          query: timelineByTransactionID,
          variables: {
            transactionId: transactionId,
            filter,
            limit,
            sortDirection: ModelSortDirection.ASC,
            nextToken: currentNextToken,
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        currentNextToken = ListData.data.timelineByTransactionID.nextToken;
        listing = [...ListData.data.timelineByTransactionID.items, ...listing];
      } while (currentNextToken);
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: TimelineGetVariables) {
    const { id, listing } = params;

    try {
      let single: Timeline | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await API.graphql<Timeline>({
          query: getTimeline,
          variables: { id },
          authMode: true
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        single = listing.data.getTimeline;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    

    if (!data.resource && !data.admin) {
      const error = new Error(`Cannot create ${singleName} without resource`);
      return showError(error);
    }
    if (!data.statusesListing && !data.admin) {
      
      const error = new Error(
        `Cannot create ${singleName} without statusesListing`
      );
      return showError(error);
    }
    // if (!data.timeSlotsListing && !data.admin) {
    //   console.log("Hi from 3");
    //   const error = new Error(
    //     `Cannot create ${singleName} without timeSlotsListing`
    //   );
    //   return showError(error);
    // }

    if (data.bookingId) {
      if (
        data.accompaniedCount &&
        data.accompaniedCount !== data.resource.accompaniedCount
      ) {
        const actionName = BookingTimelineActions.GUEST_COUNT;
        const oldData = data.resource.accompaniedCount
          ? data.resource.accompaniedCount.toString()
          : "";
        const newData = data.accompaniedCount.toString();

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (data.date && data.date !== data.resource.date) {
        const actionName = BookingTimelineActions.DATE;
        const oldData = data.resource.date ? data.resource.date : "";
        const newData = data.date;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }
      
      if (data.tableName && data.tableName !== data.resource.tableName) {
        const actionName = BookingTimelineActions.TABLE;
        const oldData = data.resource.tableName;
        const newData = data.tableName;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: data.bookingId,
          customerId: "",
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }
    }

    if (data.customerId) {
      console.log("Whats up");
      if (
        data.flags &&
        data.flags.length > 0 &&
        data.flags !== data.resource.flags
      ) {
        let actionName = TimelineActions.ADD_FLAG;

        if (data.flags.length < data.resource.flags.length)
          actionName = TimelineActions.REMOVE_FLAG;

        const oldData = mergeListOfStringsByDash(data.resource.flagsName);
        const newData = mergeListOfStringsByDash(data.flagsName);

        if (newData) {
          const createInput: CreateTimelineInput = {
            actionName: actionName,
            oldStatus: oldData,
            newStatus: newData,
            bookingId: "",
            customerId: data.customerId,
            deleted: "0",
            createdAt: new Date().toISOString(),
            createdByID: userID,
            createdByName: userName,
          };

          const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
            query: createTimeline,
            variables: { input: createInput },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          });
          dispatch(
            setListing([timeline.data.createTimeline, ...data.timelinesListing])
          );
        }
      }

      if (data.interests && data.interests !== data.resource.interests) {
        let actionName = TimelineActions.ADD_INTEREST;

        if (data.interests.length < data.resource.interests.length)
          actionName = TimelineActions.REMOVE_INTEREST;

        const oldData = mergeListOfStringsByDash(data.resource.interestsName);
        const newData = mergeListOfStringsByDash(data.interestsName);

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (
        data.phone_number &&
        data.phone_number !== data.resource.phone_number
      ) {
        const actionName = TimelineActions.CHANGE_PHONE_NUMBER;
        const oldData = data.resource.phone_number
          ? data.resource.phone_number
          : "";
        const newData = data.phone_number;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (
        data.email_verified &&
        data.email_verified !== data.resource.email_verified
      ) {
        const actionName = TimelineActions.CHANGE_VERIFICATION_STATUS;
        const oldData = data.resource.email_verified
          ? data.resource.email_verified
          : "";
        const newData = data.email_verified;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (data.email && data.email !== data.resource.email) {
        const actionName = TimelineActions.CHANGE_EMAIL;
        const oldData = data.resource.email ? data.resource.email : "";
        const newData = data.email;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
          transactionId: data.transactionId,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }
      if (data.birthdate && data.birthdate !== data.resource.birthdate) {
        const actionName = TimelineActions.CHANGE_BIRTHDATE;
        const oldData = data.resource.birthdate ? data.resource.birthdate : "";
        const newData = data.birthdate;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (data.group && data.group !== data.resource.group) {
        const actionName = TimelineActions.CHANGE_GROUP;
        const oldData = data.resource.group ? data.resource.group : "";
        const newData = data.group;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (data.name && data.name !== data.resource.name) {
        const actionName = TimelineActions.CHANGE_NAME;
        const oldData = data.resource.name ? data.resource.name : "";
        const newData = data.name;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (data.address && data.address !== data.resource.address) {
        let actionName = TimelineActions.CHANGE_ADDRESS;
        const oldAddressObj = data.resource.address
          ? JSON.parse(data.resource.address)
          : [];
        const newAddressObj = data.address ? JSON.parse(data.address) : [];
        // console.log({ oldAddressObj, newAddressObj });

        // Function to compare two addresses
        const compareAddresses = (address1: any, address2: any) => {
          // Compare all attributes
          return (
            address1.buildingNumber === address2.buildingNumber &&
            address1.streetName === address2.streetName &&
            address1.appartmentNumber === address2.appartmentNumber &&
            address1.phoneNumber === address2.phoneNumber
          );
        };

        if (oldAddressObj.length < newAddressObj.length) {
          // New addresses added
          actionName = TimelineActions.ADD_ADDRESS;
          for (const newAddr of newAddressObj) {
            const isNewAddress = !oldAddressObj.some((oldAddr: any) =>
              compareAddresses(oldAddr, newAddr)
            );
            if (isNewAddress) {
              // Address added
              // console.log("Address added:", newAddr);

              // Include new address in newStatus
              const newStatus = `${newAddr.buildingNumber}, ${newAddr.streetName}, ${newAddr.zone}`;

              // Create timeline entry for added address
              const createInput = {
                actionName: actionName,
                oldStatus: "",
                newStatus: newStatus,
                bookingId: "",
                customerId: data.customerId,
                deleted: "0",
                createdAt: new Date().toISOString(),
                createdByID: userID,
                createdByName: userName,
              };

              const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
                query: createTimeline,
                variables: { input: createInput },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
              });
              dispatch(
                setListing([
                  timeline.data.createTimeline,
                  ...data.timelinesListing,
                ])
              );
            }
          }
        } else {
          const ignoredKeys = ["zoneID", "longitude", "latitude"];

          for (let i = 0; i < oldAddressObj.length; i++) {
            const modifiedParts: any = [];
            const oldAddr = oldAddressObj[i];
            const newAddr = newAddressObj[i];

            for (const key in newAddr) {
              if (oldAddr.hasOwnProperty(key) && !ignoredKeys.includes(key)) {
                if (oldAddr[key] !== newAddr[key]) {
                  modifiedParts.push(key);
                }
              }
            }

            if (modifiedParts.length > 0) {
              const oldStatus = modifiedParts
                .map((part: any) => `${part}: ${oldAddressObj[0][part]}`)
                .join(", ");
              const newStatus = modifiedParts
                .map((part: any) => `${part}: ${newAddr[part]}`)
                .join(", ");

              const createInput = {
                actionName: actionName,
                oldStatus,
                newStatus,
                bookingId: "",
                customerId: data.customerId,
                deleted: "0",
                createdAt: new Date().toISOString(),
                createdByID: userID,
                createdByName: userName,
              };

              const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
                query: createTimeline,
                variables: { input: createInput },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
              });

              dispatch(
                setListing([
                  timeline.data.createTimeline,
                  ...data.timelinesListing,
                ])
              );
            }
          }
        }
      }

      if (data.instagram && data.instagram !== data.resource.instagram) {
        const actionName = TimelineActions.CHANGE_INSTAGRAM;
        const oldData = data.resource.instagram ? data.resource.instagram : "";
        const newData = data.instagram;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (data.facebook && data.facebook !== data.resource.facebook) {
        const actionName = TimelineActions.CHANGE_FACEBOOK;
        const oldData = data.resource.facebook ? data.resource.facebook : "";
        const newData = data.facebook;

        const createInput: CreateTimelineInput = {
          actionName: actionName,
          oldStatus: oldData,
          newStatus: newData,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };
        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }

      if (data.admin) {
        const createInput: CreateTimelineInput = {
          actionName: data.actionName,
          oldStatus: data.oldStatus,
          newStatus: data.newStatus,
          bookingId: "",
          customerId: data.customerId,
          deleted: "0",
          createdAt: new Date().toISOString(),
          createdByID: userID,
          createdByName: userName,
        };

        const timeline: any = await API.graphql<GraphQLQuery<Timeline>>({
          query: createTimeline,
          variables: { input: createInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });

        dispatch(
          setListing([timeline.data.createTimeline, ...data.timelinesListing])
        );
      }
    }
  }

  async function createOnline(params: CreateOnlineVariables, isAuth = true) {
    try {
      const createInput: CreateTimelineInput = {
        actionName: params.actionName!,
        oldStatus: params.oldStatus ?? "",
        newStatus: params.newStatus ?? "",
        bookingId: params.bookingId ?? "",
        customerId: params.customerId!,
        createdByID: params.createdByID!,
        createdByName: params.createdByName!,
        deleted: "0",
        createdAt: new Date().toISOString(),
        transactionId: params.transactionId,
      };

      await API.graphql<GraphQLQuery<Timeline>>({
        query: createTimeline,
        variables: { input: createInput },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: TimelineUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        // console.log({ original });
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateTimelineInput = {
        id: original.id,

        actionName: data.actionName ? data.actionName : original!.actionName,
        oldStatus: data.oldStatus ? data.oldStatus : original!.oldStatus,
        newStatus: data.newStatus ? data.newStatus : original!.newStatus,
        bookingId: data.bookingId ? data.bookingId : original!.bookingId,
        customerId: data.customerId ? data.customerId : original!.customerId,

        _version: original._version,
      };

      await API.graphql<Timeline>({
        query: updateTimeline,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      // showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: TimelineGetVariables) {
    try {
      const original: any = await get(params);

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateTimelineInput = {
        id: original.id,
        deleted: "1",
        _version: original._version,
      };

      await API.graphql<Timeline>({
        query: updateTimeline,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      // showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: TimelineBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    // showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: TimelineGetVariables) {
    const { id, listing } = params;

    try {
      await API.graphql<Timeline>({
        query: deleteTimeline,
        variables: { id: id },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      // showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Timeline[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.actionName, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "actionName",
      numeric: false,
      disablePadding: false,
      label: "Action",
    },
    {
      id: "oldStatus",
      numeric: false,
      disablePadding: false,
      label: "Old Status",
    },
    {
      id: "newStatus",
      numeric: false,
      disablePadding: false,
      label: "New Status",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["actionName", "oldStatus", "newStatus"];

  const api: any = {};

  api[`${listingName}Model`] = Timeline as any;
  api[`${listingName}CreateSubscription`] = onCreateTimeline;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByCustomerID`] = fetchByCustomerID;
  api[`${listingName}FetchByTransactionID`] = fetchByTransactionID;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}CreateOnline`] = createOnline;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Timeline[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
