import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      light: "#92A68A",
      main: "#125D77", // similar to the main color in "The Drive" logo
      dark: "#125D77",
      "100": "#FBFFEF",
      "200": "#565E6C",
      "300": "#90900E",
      "400": "#f9fcdf",
      "500": "#1dd75a", // Success
      "600": "#e05858", // Cancel
    },
    secondary: {
      light: "#FFE699",
      main: "#FFD966", // matches the yellow in buttons and accents
      dark: "#CCAC00",
    },
    text: {
      primary: "#1B1B1B", // dark text color as seen on headings
      secondary: "#717171", // grey for subtext and less prominent text
    },
    background: {
      paper: "#FFFFFF",
      default: "#F6F6F6", // light background
    },
    success: {
      main: "#00b0ff", // blue used in charts and icons
    },
    info: {
      main: "#33bfff", // lighter blue shade
    },
  },
  shape: {
    borderRadius: 8, // rounded borders as seen in some elements
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h6: {
      fontWeight: 700, // bold headings like "Dashboard" and other titles
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#1B1B1B",
    },
  },
};

export default lightThemeOptions;
