import { ColorChangeHandler, SketchPicker, ChromePicker } from "react-color";
import { Pages } from "../../../constants/enums";
import LightBox from "../LightBox";
import Section from "../Section";
import { useEffect, useState } from "react";

interface ColorPicker {
  defaultColor: string;
  handleChange: (color: string) => {};
}

const ColorPicker: React.FC<ColorPicker> = ({ defaultColor, handleChange }) => {
  let [color, setColor] = useState(defaultColor || "#fff");

  useEffect(() => {
    handleChange(color);
  }, []);
  console.log({ defaultColor });
  return (
    <Section label="Color Picker">
      <LightBox>
        <ChromePicker
          styles={{
            default: {
              picker: { userSelect: "none" },
            },
          }}
          color={color}
          onChange={(color) => {
            setColor(color.hex);
            handleChange(color.hex);
          }}
          onChangeComplete={(color) => {
            setColor(color.hex);
            handleChange(color.hex);
          }}
        />
      </LightBox>
    </Section>
  );
};

export default ColorPicker;
