// actions
const SET_RedirectToUserOrderCallCenter = "callCenter/SET";
const SET_Is_Order_Cart_Open = "callCenter/SET_Is_Order_Cart_Open";


const DEFAULT_STATE = {
    redirectToUserOrderCallCenter: false,
    isOrderCartOpened: false
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET_RedirectToUserOrderCallCenter:
      return Object.assign({}, state, { redirectToUserOrderCallCenter: action.redirectToUserOrderCallCenter });
      case SET_Is_Order_Cart_Open:
        return Object.assign({}, state, {
          isOrderCartOpened: action.isOrderCartOpened,
        });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setRedirectToUserOrderCallCenter = (redirectToUserOrderCallCenter: boolean) => ({ redirectToUserOrderCallCenter, type: SET_RedirectToUserOrderCallCenter });

export const setIsOrderCartOpenedCallCenter = (isOrderCartOpened: boolean) => ({
  isOrderCartOpened,
  type: SET_Is_Order_Cart_Open,
});
