/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      scannedCount
      count
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      scannedCount
      count
      __typename
    }
  }
`;
export const ByPhoneNumber = /* GraphQL */ `
  query ByPhoneNumber(
    $phone_number: String!
    $deletedNameCreatedAt: ModelUserUsersByPhoneNumberCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByPhoneNumber(
      phone_number: $phone_number
      deletedNameCreatedAt: $deletedNameCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      scannedCount
      count
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      accountID
      bookingID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logo
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        kpiReportResetFrequency
        agentBarResetFrequency
        dynamicStatus
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        logo
        domain
        siteTitle
        guestsCount
        tagline
        description
        siteAddress
        defaultLanguage
        languages
        features
        status
        socialLinks
        deleted
        createdAt
        createdByID
        createdByName
        callCenterEmployeesIds
        kpiReportResetFrequency
        agentBarResetFrequency
        dynamicStatus
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFeatures = /* GraphQL */ `
  query SyncFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        icon
        slug
        precedence
        parent
        private
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMedia = /* GraphQL */ `
  query SyncMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedia(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        attachments
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getConcept = /* GraphQL */ `
  query GetConcept($id: ID!) {
    getConcept(id: $id) {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listConcepts = /* GraphQL */ `
  query ListConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncConcepts = /* GraphQL */ `
  query SyncConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const conceptsByParentConceptIDAndPrecedence = /* GraphQL */ `
  query ConceptsByParentConceptIDAndPrecedence(
    $parentConceptID: ID!
    $precedence: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptsByParentConceptIDAndPrecedence(
      parentConceptID: $parentConceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCategoryTag = /* GraphQL */ `
  query GetCategoryTag($id: ID!) {
    getCategoryTag(id: $id) {
      id
      parentConceptID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCategoryTags = /* GraphQL */ `
  query ListCategoryTags(
    $filter: ModelCategoryTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCategoryTags = /* GraphQL */ `
  query SyncCategoryTags(
    $filter: ModelCategoryTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategoryTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const categoryTagsByParentConceptIDAndPrecedence = /* GraphQL */ `
  query CategoryTagsByParentConceptIDAndPrecedence(
    $parentConceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryTagsByParentConceptIDAndPrecedence(
      parentConceptID: $parentConceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTender = /* GraphQL */ `
  query GetTender($id: ID!) {
    getTender(id: $id) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
  }
`;
export const listTenders = /* GraphQL */ `
  query ListTenders(
    $id: ID
    $filter: ModelTenderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTenders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTenders = /* GraphQL */ `
  query SyncTenders(
    $filter: ModelTenderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTenders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderType = /* GraphQL */ `
  query GetOrderType($id: ID!) {
    getOrderType(id: $id) {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
  }
`;
export const listOrderTypes = /* GraphQL */ `
  query ListOrderTypes(
    $id: ID
    $filter: ModelOrderTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderTypes = /* GraphQL */ `
  query SyncOrderTypes(
    $filter: ModelOrderTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getParentConcept = /* GraphQL */ `
  query GetParentConcept($id: ID!) {
    getParentConcept(id: $id) {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues {
        items {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptTendersId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          orderType {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptOrderTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          containerColor
          avgOrderCompletionEstimate
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      index
      categoryTags {
        items {
          id
          parentConceptID
          name
          image
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listParentConcepts = /* GraphQL */ `
  query ListParentConcepts(
    $filter: ModelParentConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParentConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        createdAt
        createdByID
        createdByName
        image
        name
        logo
        type
        venues {
          items {
            id
            accountID
            name
            kiosks
            merchantID
            merchantUsername
            merchantPassword
            merchantIntegrationNumber
            merchantAPIKey
            simphonyServerBaseURL
            simphonyShortOrgName
            simphonyLocRef
            simphonyToken
            revenueCenterID
            employeeID
            tenderID
            tenders {
              nextToken
              startedAt
              __typename
            }
            orderType {
              nextToken
              startedAt
              __typename
            }
            discountID
            useServiceCharge
            serviceChargeId
            specialRequestID
            addressID
            simphonyAuthClientId
            simphonyAuthUsername
            simphonyAuthPassword
            simphonyAuthBaseURL
            orderTypeDineIn
            orderTypeTakeAway
            orderTypeDelivery
            simphonyTakeAwayRvc
            simphonyDeliveryRvc
            exportInterval
            deleted
            createdAt
            createdByID
            createdByName
            foodicsToken
            enableTableFoodics
            image
            logo
            album
            type
            rating
            location
            description
            areas
            seatTypes
            needAssistance
            spaBooking
            showerBooking
            cigarMenu
            precedence
            isPinRequired
            viewOnly
            openTime
            closeTime
            varifyForAmount
            varifyFirstOrder
            varifyNewAddress
            sliderImages
            receiveWaiters
            WaiterNotificationOption
            applyLoyalty
            rewardPoints
            moneySpent
            afterVat
            afterDeliveryFee
            beforePromoCode
            pointsCalcWithPromoCode
            isBusy
            allowOnlineOrdering
            minOrderVal
            vatPercentage
            serviceChargePercentage
            addDeliveryToVat
            categoryTagIDs
            parentConceptID
            containerColor
            avgOrderCompletionEstimate
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        index
        categoryTags {
          items {
            id
            parentConceptID
            name
            image
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncParentConcepts = /* GraphQL */ `
  query SyncParentConcepts(
    $filter: ModelParentConceptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParentConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deleted
        createdAt
        createdByID
        createdByName
        image
        name
        logo
        type
        venues {
          items {
            id
            accountID
            name
            kiosks
            merchantID
            merchantUsername
            merchantPassword
            merchantIntegrationNumber
            merchantAPIKey
            simphonyServerBaseURL
            simphonyShortOrgName
            simphonyLocRef
            simphonyToken
            revenueCenterID
            employeeID
            tenderID
            tenders {
              nextToken
              startedAt
              __typename
            }
            orderType {
              nextToken
              startedAt
              __typename
            }
            discountID
            useServiceCharge
            serviceChargeId
            specialRequestID
            addressID
            simphonyAuthClientId
            simphonyAuthUsername
            simphonyAuthPassword
            simphonyAuthBaseURL
            orderTypeDineIn
            orderTypeTakeAway
            orderTypeDelivery
            simphonyTakeAwayRvc
            simphonyDeliveryRvc
            exportInterval
            deleted
            createdAt
            createdByID
            createdByName
            foodicsToken
            enableTableFoodics
            image
            logo
            album
            type
            rating
            location
            description
            areas
            seatTypes
            needAssistance
            spaBooking
            showerBooking
            cigarMenu
            precedence
            isPinRequired
            viewOnly
            openTime
            closeTime
            varifyForAmount
            varifyFirstOrder
            varifyNewAddress
            sliderImages
            receiveWaiters
            WaiterNotificationOption
            applyLoyalty
            rewardPoints
            moneySpent
            afterVat
            afterDeliveryFee
            beforePromoCode
            pointsCalcWithPromoCode
            isBusy
            allowOnlineOrdering
            minOrderVal
            vatPercentage
            serviceChargePercentage
            addDeliveryToVat
            categoryTagIDs
            parentConceptID
            containerColor
            avgOrderCompletionEstimate
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        index
        categoryTags {
          items {
            id
            parentConceptID
            name
            image
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTimeline = /* GraphQL */ `
  query GetTimeline($id: ID!) {
    getTimeline(id: $id) {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTimelines = /* GraphQL */ `
  query ListTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        transactionId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTimelines = /* GraphQL */ `
  query SyncTimelines(
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        transactionId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const timelineByTransactionID = /* GraphQL */ `
  query TimelineByTransactionID(
    $transactionId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timelineByTransactionID(
      transactionId: $transactionId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        transactionId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const timelineByCustomerID = /* GraphQL */ `
  query TimelineByCustomerID(
    $customerId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timelineByCustomerID(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actionName
        oldStatus
        newStatus
        bookingId
        transactionId
        customerId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCall = /* GraphQL */ `
  query GetCall($id: ID!) {
    getCall(id: $id) {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
  }
`;
export const listCalls = /* GraphQL */ `
  query ListCalls(
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone_number
        customer {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          pictureCrop
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          spending
          prevOrderDate
          lastOrderDate
          promocodes {
            name
            promocodeId
            canBeUsed
            discountVal
            discountLimit
            isDisplayed
            __typename
          }
          mostOrderedItems
          points
          deleted
          createdAt
          createdByID
          createdByName
          cognitoUsername
          cognitoSub
          autoGeneratedPassword
          updated
          sub
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        createdByID
        createdByName
        endedAt
        duration
        date
        employeeId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        callCustomerId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCalls = /* GraphQL */ `
  query SyncCalls(
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCalls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        phone_number
        customer {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          pictureCrop
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          spending
          prevOrderDate
          lastOrderDate
          promocodes {
            name
            promocodeId
            canBeUsed
            discountVal
            discountLimit
            isDisplayed
            __typename
          }
          mostOrderedItems
          points
          deleted
          createdAt
          createdByID
          createdByName
          cognitoUsername
          cognitoSub
          autoGeneratedPassword
          updated
          sub
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        createdByID
        createdByName
        endedAt
        duration
        date
        employeeId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        callCustomerId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminRole = /* GraphQL */ `
  query GetAdminRole($id: ID!) {
    getAdminRole(id: $id) {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminRoles = /* GraphQL */ `
  query ListAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminRoles = /* GraphQL */ `
  query SyncAdminRoles(
    $filter: ModelAdminRoleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminGroup = /* GraphQL */ `
  query GetAdminGroup($id: ID!) {
    getAdminGroup(id: $id) {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminGroups = /* GraphQL */ `
  query ListAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminGroups = /* GraphQL */ `
  query SyncAdminGroups(
    $filter: ModelAdminGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        roles
        users
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserConcepts = /* GraphQL */ `
  query GetUserConcepts($id: ID!) {
    getUserConcepts(id: $id) {
      id
      defaultConcept
      concepts
      parentConcepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserConcepts = /* GraphQL */ `
  query ListUserConcepts(
    $filter: ModelUserConceptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        defaultConcept
        concepts
        parentConcepts
        conceptsRoles
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserConcepts = /* GraphQL */ `
  query SyncUserConcepts(
    $filter: ModelUserConceptsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        defaultConcept
        concepts
        parentConcepts
        conceptsRoles
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transactionID
        date
        guestName
        guestPhone
        guestID
        guestGroup
        guestsNames
        bookingID
        bookingDate
        amount_cents
        status
        failureReason
        type
        timeSlots
        tables
        conceptID
        currency
        refund
        reference_orderID
        ownerID
        refunded_amount_cents
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        transactionID
        date
        guestName
        guestPhone
        guestID
        guestGroup
        guestsNames
        bookingID
        bookingDate
        amount_cents
        status
        failureReason
        type
        timeSlots
        tables
        conceptID
        currency
        refund
        reference_orderID
        ownerID
        refunded_amount_cents
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const transactionsByDeleted = /* GraphQL */ `
  query TransactionsByDeleted(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByDeleted(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionID
        date
        guestName
        guestPhone
        guestID
        guestGroup
        guestsNames
        bookingID
        bookingDate
        amount_cents
        status
        failureReason
        type
        timeSlots
        tables
        conceptID
        currency
        refund
        reference_orderID
        ownerID
        refunded_amount_cents
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPaymentStat = /* GraphQL */ `
  query GetPaymentStat($id: ID!) {
    getPaymentStat(id: $id) {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPaymentStats = /* GraphQL */ `
  query ListPaymentStats(
    $filter: ModelPaymentStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        conceptID
        year
        month
        monthName
        earning
        refund
        earningTrxCount
        refundTrxCount
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPaymentStats = /* GraphQL */ `
  query SyncPaymentStats(
    $filter: ModelPaymentStatFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPaymentStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        conceptID
        year
        month
        monthName
        earning
        refund
        earningTrxCount
        refundTrxCount
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      conceptID
      parentConceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          pricesItems {
            id
            conceptID
            parentConceptID
            name
            multiLanguages
            price
            symphonyID
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          choiceGroupsItems {
            id
            conceptID
            parentConceptID
            name
            required
            maxNumberOfChoices
            minNumberOfChoices
            precedence
            type
            choices
            choiceItems {
              nextToken
              startedAt
              __typename
            }
            deleted
            createdAt
            createdByID
            createdByName
            multiLanguages
            simphonyID
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          choiceGroupsRules {
            id
            minNumberOfChoices
            maxNumberOfChoices
            __typename
          }
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $id: ID
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        conceptID
        parentConceptID
        preparationAreaID
        subCategory
        name
        guestView
        guestOrder
        staffOrder
        image
        darkImage
        menuItems {
          items {
            id
            conceptID
            kioskID
            name
            description
            image
            precedence
            categoryID
            categoryName
            outOfStock
            requiredPoints
            symphonyID
            comboItemID
            ratings
            prices
            choiceGroups
            pricesItems {
              id
              conceptID
              parentConceptID
              name
              multiLanguages
              price
              symphonyID
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsItems {
              id
              conceptID
              parentConceptID
              name
              required
              maxNumberOfChoices
              minNumberOfChoices
              precedence
              type
              choices
              deleted
              createdAt
              createdByID
              createdByName
              multiLanguages
              simphonyID
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsRules {
              id
              minNumberOfChoices
              maxNumberOfChoices
              __typename
            }
            ExtraChoiceGroups
            enabled
            hasLimitedQuantity
            quantity
            autoRestock
            restockQuantity
            deleted
            createdAt
            createdByID
            createdByName
            quantityOrdered
            performancePerDay
            multiLanguages
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        symphonyID
        isHidden
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        parentConceptID
        preparationAreaID
        subCategory
        name
        guestView
        guestOrder
        staffOrder
        image
        darkImage
        menuItems {
          items {
            id
            conceptID
            kioskID
            name
            description
            image
            precedence
            categoryID
            categoryName
            outOfStock
            requiredPoints
            symphonyID
            comboItemID
            ratings
            prices
            choiceGroups
            pricesItems {
              id
              conceptID
              parentConceptID
              name
              multiLanguages
              price
              symphonyID
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsItems {
              id
              conceptID
              parentConceptID
              name
              required
              maxNumberOfChoices
              minNumberOfChoices
              precedence
              type
              choices
              deleted
              createdAt
              createdByID
              createdByName
              multiLanguages
              simphonyID
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsRules {
              id
              minNumberOfChoices
              maxNumberOfChoices
              __typename
            }
            ExtraChoiceGroups
            enabled
            hasLimitedQuantity
            quantity
            autoRestock
            restockQuantity
            deleted
            createdAt
            createdByID
            createdByName
            quantityOrdered
            performancePerDay
            multiLanguages
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        symphonyID
        isHidden
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const categoryByConceptID = /* GraphQL */ `
  query CategoryByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        parentConceptID
        preparationAreaID
        subCategory
        name
        guestView
        guestOrder
        staffOrder
        image
        darkImage
        menuItems {
          items {
            id
            conceptID
            kioskID
            name
            description
            image
            precedence
            categoryID
            categoryName
            outOfStock
            requiredPoints
            symphonyID
            comboItemID
            ratings
            prices
            choiceGroups
            pricesItems {
              id
              conceptID
              parentConceptID
              name
              multiLanguages
              price
              symphonyID
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsItems {
              id
              conceptID
              parentConceptID
              name
              required
              maxNumberOfChoices
              minNumberOfChoices
              precedence
              type
              choices
              deleted
              createdAt
              createdByID
              createdByName
              multiLanguages
              simphonyID
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsRules {
              id
              minNumberOfChoices
              maxNumberOfChoices
              __typename
            }
            ExtraChoiceGroups
            enabled
            hasLimitedQuantity
            quantity
            autoRestock
            restockQuantity
            deleted
            createdAt
            createdByID
            createdByName
            quantityOrdered
            performancePerDay
            multiLanguages
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        symphonyID
        isHidden
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const categoryByParentConceptID = /* GraphQL */ `
  query CategoryByParentConceptID(
    $parentConceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByParentConceptID(
      parentConceptID: $parentConceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        parentConceptID
        preparationAreaID
        subCategory
        name
        guestView
        guestOrder
        staffOrder
        image
        darkImage
        menuItems {
          items {
            id
            conceptID
            kioskID
            name
            description
            image
            precedence
            categoryID
            categoryName
            outOfStock
            requiredPoints
            symphonyID
            comboItemID
            ratings
            prices
            choiceGroups
            pricesItems {
              id
              conceptID
              parentConceptID
              name
              multiLanguages
              price
              symphonyID
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsItems {
              id
              conceptID
              parentConceptID
              name
              required
              maxNumberOfChoices
              minNumberOfChoices
              precedence
              type
              choices
              deleted
              createdAt
              createdByID
              createdByName
              multiLanguages
              simphonyID
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            choiceGroupsRules {
              id
              minNumberOfChoices
              maxNumberOfChoices
              __typename
            }
            ExtraChoiceGroups
            enabled
            hasLimitedQuantity
            quantity
            autoRestock
            restockQuantity
            deleted
            createdAt
            createdByID
            createdByName
            quantityOrdered
            performancePerDay
            multiLanguages
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        symphonyID
        isHidden
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!) {
    getMenuItem(id: $id) {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      pricesItems {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsItems {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          items {
            id
            conceptID
            name
            price
            precedence
            choiceGroupID
            choiceGroupName
            deleted
            createdAt
            createdByID
            createdByName
            symphonyID
            multiLanguages
            definitionNumber
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $id: ID
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          choiceItems {
            items {
              id
              conceptID
              name
              price
              precedence
              choiceGroupID
              choiceGroupName
              deleted
              createdAt
              createdByID
              createdByName
              symphonyID
              multiLanguages
              definitionNumber
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMenuItems = /* GraphQL */ `
  query SyncMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          choiceItems {
            items {
              id
              conceptID
              name
              price
              precedence
              choiceGroupID
              choiceGroupName
              deleted
              createdAt
              createdByID
              createdByName
              symphonyID
              multiLanguages
              definitionNumber
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuItemByConceptID = /* GraphQL */ `
  query MenuItemByConceptID(
    $conceptID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemByConceptID(
      conceptID: $conceptID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          choiceItems {
            items {
              id
              conceptID
              name
              price
              precedence
              choiceGroupID
              choiceGroupName
              deleted
              createdAt
              createdByID
              createdByName
              symphonyID
              multiLanguages
              definitionNumber
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuItemsByCategoryIDAndPrecedence = /* GraphQL */ `
  query MenuItemsByCategoryIDAndPrecedence(
    $categoryID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByCategoryIDAndPrecedence(
      categoryID: $categoryID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          choiceItems {
            items {
              id
              conceptID
              name
              price
              precedence
              choiceGroupID
              choiceGroupName
              deleted
              createdAt
              createdByID
              createdByName
              symphonyID
              multiLanguages
              definitionNumber
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrices = /* GraphQL */ `
  query SyncPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const priceByConceptID = /* GraphQL */ `
  query PriceByConceptID(
    $conceptID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priceByConceptID(
      conceptID: $conceptID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const priceByParentConceptID = /* GraphQL */ `
  query PriceByParentConceptID(
    $parentConceptID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priceByParentConceptID(
      parentConceptID: $parentConceptID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChoiceGroups = /* GraphQL */ `
  query GetChoiceGroups($id: ID!) {
    getChoiceGroups(id: $id) {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChoiceGroups = /* GraphQL */ `
  query ListChoiceGroups(
    $filter: ModelChoiceGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChoiceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          items {
            id
            conceptID
            name
            price
            precedence
            choiceGroupID
            choiceGroupName
            deleted
            createdAt
            createdByID
            createdByName
            symphonyID
            multiLanguages
            definitionNumber
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChoiceGroups = /* GraphQL */ `
  query SyncChoiceGroups(
    $filter: ModelChoiceGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChoiceGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          items {
            id
            conceptID
            name
            price
            precedence
            choiceGroupID
            choiceGroupName
            deleted
            createdAt
            createdByID
            createdByName
            symphonyID
            multiLanguages
            definitionNumber
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const choiceGroupsByConceptID = /* GraphQL */ `
  query ChoiceGroupsByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChoiceGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    choiceGroupsByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          items {
            id
            conceptID
            name
            price
            precedence
            choiceGroupID
            choiceGroupName
            deleted
            createdAt
            createdByID
            createdByName
            symphonyID
            multiLanguages
            definitionNumber
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const choiceGroupsByParentConceptID = /* GraphQL */ `
  query ChoiceGroupsByParentConceptID(
    $parentConceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChoiceGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    choiceGroupsByParentConceptID(
      parentConceptID: $parentConceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          items {
            id
            conceptID
            name
            price
            precedence
            choiceGroupID
            choiceGroupName
            deleted
            createdAt
            createdByID
            createdByName
            symphonyID
            multiLanguages
            definitionNumber
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChoice = /* GraphQL */ `
  query GetChoice($id: ID!) {
    getChoice(id: $id) {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChoices = /* GraphQL */ `
  query ListChoices(
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChoices = /* GraphQL */ `
  query SyncChoices(
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const choiceByConceptID = /* GraphQL */ `
  query ChoiceByConceptID(
    $conceptID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    choiceByConceptID(
      conceptID: $conceptID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const choicesByChoiceGroupIDAndPrecedence = /* GraphQL */ `
  query ChoicesByChoiceGroupIDAndPrecedence(
    $choiceGroupID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    choicesByChoiceGroupIDAndPrecedence(
      choiceGroupID: $choiceGroupID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          selectedChoices {
            id
            name
            price
            __typename
          }
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        userID
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        specialRequest
        deliveryFee
        subTotal
        subTaxTotal
        totalPrice
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCarts = /* GraphQL */ `
  query SyncCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        userID
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        specialRequest
        deliveryFee
        subTotal
        subTaxTotal
        totalPrice
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cartByUserID = /* GraphQL */ `
  query CartByUserID(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cartByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        userID
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        specialRequest
        deliveryFee
        subTotal
        subTaxTotal
        totalPrice
        deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminStatus = /* GraphQL */ `
  query GetAdminStatus($id: ID!) {
    getAdminStatus(id: $id) {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminStatuses = /* GraphQL */ `
  query ListAdminStatuses(
    $filter: ModelAdminStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        accountID
        name
        averageCallTime
        numberOfCalls
        idelDuration
        activeDuration
        awayDuration
        lastCallTime
        agentBarAverageCallTime
        agentBarNumberOfCalls
        type
        waiterStatus
        totalAverageRatio
        performancePerDay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminStatuses = /* GraphQL */ `
  query SyncAdminStatuses(
    $filter: ModelAdminStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        accountID
        name
        averageCallTime
        numberOfCalls
        idelDuration
        activeDuration
        awayDuration
        lastCallTime
        agentBarAverageCallTime
        agentBarNumberOfCalls
        type
        waiterStatus
        totalAverageRatio
        performancePerDay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ByTotalAverageRatio = /* GraphQL */ `
  query ByTotalAverageRatio(
    $name: String!
    $totalAverageRatio: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByTotalAverageRatio(
      name: $name
      totalAverageRatio: $totalAverageRatio
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        accountID
        name
        averageCallTime
        numberOfCalls
        idelDuration
        activeDuration
        awayDuration
        lastCallTime
        agentBarAverageCallTime
        agentBarNumberOfCalls
        type
        waiterStatus
        totalAverageRatio
        performancePerDay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOnlineOrder = /* GraphQL */ `
  query GetOnlineOrder($id: ID!) {
    getOnlineOrder(id: $id) {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          selectedChoices {
            id
            name
            price
            __typename
          }
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        __typename
      }
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      sentToKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      promoCode {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      reviewID
      review {
        id
        accountID
        bookingID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdByID
      createdByName
      tmpField
      otp
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOnlineOrders = /* GraphQL */ `
  query ListOnlineOrders(
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnlineOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        channel
        userID
        userName
        userPhoneNumber
        dispatcherID
        dispatcherName
        totalAmount
        discountedValue
        subTotal
        subTaxTotal
        tax
        deliveryFee
        orderTime
        executionArn
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        discountID
        amount_cents
        transactionID
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        sentToKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        promoCodeID
        promoCodeName
        promoCodeAppliedTo
        promoCode {
          id
          name
          redemptions
          status
          startDate
          endDate
          appliesTo
          minOrderReq
          minOrderVal
          minOrderQty
          tenderReq
          discountVal
          discountLimit
          quota
          quotaUsed
          totalRevenue
          appliedDiscountedVal
          usePerCustomer
          channelReq
          customerEligibility
          customerReq
          spendingExceeds
          spendingBelow
          orderSinceDate
          orderFromDate
          orderToDate
          isDisplayed
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reviewID
        review {
          id
          accountID
          bookingID
          guestName
          guestId
          reason
          value
          userID
          orderID
          rate
          satisfaction
          thoughts
          itemReviews {
            itemID
            rate
            satisfaction
            multiLanguages
            __typename
          }
          photos
          multiLanguages
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdByID
        createdByName
        tmpField
        otp
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOnlineOrders = /* GraphQL */ `
  query SyncOnlineOrders(
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOnlineOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        channel
        userID
        userName
        userPhoneNumber
        dispatcherID
        dispatcherName
        totalAmount
        discountedValue
        subTotal
        subTaxTotal
        tax
        deliveryFee
        orderTime
        executionArn
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        discountID
        amount_cents
        transactionID
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        sentToKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        promoCodeID
        promoCodeName
        promoCodeAppliedTo
        promoCode {
          id
          name
          redemptions
          status
          startDate
          endDate
          appliesTo
          minOrderReq
          minOrderVal
          minOrderQty
          tenderReq
          discountVal
          discountLimit
          quota
          quotaUsed
          totalRevenue
          appliedDiscountedVal
          usePerCustomer
          channelReq
          customerEligibility
          customerReq
          spendingExceeds
          spendingBelow
          orderSinceDate
          orderFromDate
          orderToDate
          isDisplayed
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reviewID
        review {
          id
          accountID
          bookingID
          guestName
          guestId
          reason
          value
          userID
          orderID
          rate
          satisfaction
          thoughts
          itemReviews {
            itemID
            rate
            satisfaction
            multiLanguages
            __typename
          }
          photos
          multiLanguages
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdByID
        createdByName
        tmpField
        otp
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderByConceptID = /* GraphQL */ `
  query OrderByConceptID(
    $conceptID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByConceptID(
      conceptID: $conceptID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        channel
        userID
        userName
        userPhoneNumber
        dispatcherID
        dispatcherName
        totalAmount
        discountedValue
        subTotal
        subTaxTotal
        tax
        deliveryFee
        orderTime
        executionArn
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        discountID
        amount_cents
        transactionID
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        sentToKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        promoCodeID
        promoCodeName
        promoCodeAppliedTo
        promoCode {
          id
          name
          redemptions
          status
          startDate
          endDate
          appliesTo
          minOrderReq
          minOrderVal
          minOrderQty
          tenderReq
          discountVal
          discountLimit
          quota
          quotaUsed
          totalRevenue
          appliedDiscountedVal
          usePerCustomer
          channelReq
          customerEligibility
          customerReq
          spendingExceeds
          spendingBelow
          orderSinceDate
          orderFromDate
          orderToDate
          isDisplayed
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reviewID
        review {
          id
          accountID
          bookingID
          guestName
          guestId
          reason
          value
          userID
          orderID
          rate
          satisfaction
          thoughts
          itemReviews {
            itemID
            rate
            satisfaction
            multiLanguages
            __typename
          }
          photos
          multiLanguages
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdByID
        createdByName
        tmpField
        otp
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderByStatus = /* GraphQL */ `
  query OrderByStatus(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        channel
        userID
        userName
        userPhoneNumber
        dispatcherID
        dispatcherName
        totalAmount
        discountedValue
        subTotal
        subTaxTotal
        tax
        deliveryFee
        orderTime
        executionArn
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        discountID
        amount_cents
        transactionID
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        sentToKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        promoCodeID
        promoCodeName
        promoCodeAppliedTo
        promoCode {
          id
          name
          redemptions
          status
          startDate
          endDate
          appliesTo
          minOrderReq
          minOrderVal
          minOrderQty
          tenderReq
          discountVal
          discountLimit
          quota
          quotaUsed
          totalRevenue
          appliedDiscountedVal
          usePerCustomer
          channelReq
          customerEligibility
          customerReq
          spendingExceeds
          spendingBelow
          orderSinceDate
          orderFromDate
          orderToDate
          isDisplayed
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reviewID
        review {
          id
          accountID
          bookingID
          guestName
          guestId
          reason
          value
          userID
          orderID
          rate
          satisfaction
          thoughts
          itemReviews {
            itemID
            rate
            satisfaction
            multiLanguages
            __typename
          }
          photos
          multiLanguages
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdByID
        createdByName
        tmpField
        otp
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByUserID = /* GraphQL */ `
  query OrdersByUserID(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        channel
        userID
        userName
        userPhoneNumber
        dispatcherID
        dispatcherName
        totalAmount
        discountedValue
        subTotal
        subTaxTotal
        tax
        deliveryFee
        orderTime
        executionArn
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        discountID
        amount_cents
        transactionID
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        sentToKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        promoCodeID
        promoCodeName
        promoCodeAppliedTo
        promoCode {
          id
          name
          redemptions
          status
          startDate
          endDate
          appliesTo
          minOrderReq
          minOrderVal
          minOrderQty
          tenderReq
          discountVal
          discountLimit
          quota
          quotaUsed
          totalRevenue
          appliedDiscountedVal
          usePerCustomer
          channelReq
          customerEligibility
          customerReq
          spendingExceeds
          spendingBelow
          orderSinceDate
          orderFromDate
          orderToDate
          isDisplayed
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reviewID
        review {
          id
          accountID
          bookingID
          guestName
          guestId
          reason
          value
          userID
          orderID
          rate
          satisfaction
          thoughts
          itemReviews {
            itemID
            rate
            satisfaction
            multiLanguages
            __typename
          }
          photos
          multiLanguages
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdByID
        createdByName
        tmpField
        otp
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orderByDeleted = /* GraphQL */ `
  query OrderByDeleted(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOnlineOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByDeleted(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        statusID
        status
        reason
        orderType
        channel
        userID
        userName
        userPhoneNumber
        dispatcherID
        dispatcherName
        totalAmount
        discountedValue
        subTotal
        subTaxTotal
        tax
        deliveryFee
        orderTime
        executionArn
        specialRequest
        orderAddress
        orderedItems {
          menuItemID
          categoryID
          name
          quantity
          selectedChoiceGroups {
            id
            name
            selectedChoices {
              id
              name
              price
              __typename
            }
            precedence
            __typename
          }
          price
          priceName
          priceID
          priceMultiLanguages
          multiLanguages
          notes
          image
          __typename
        }
        discountID
        amount_cents
        transactionID
        paymentStatus
        paymentType
        referenceId
        cardHolderNumber
        cardHolderName
        cardSchemeName
        deleted
        createdAt
        sentToKitchenAt
        readyAt
        outForDeliveryAt
        deliveredAt
        promoCodeID
        promoCodeName
        promoCodeAppliedTo
        promoCode {
          id
          name
          redemptions
          status
          startDate
          endDate
          appliesTo
          minOrderReq
          minOrderVal
          minOrderQty
          tenderReq
          discountVal
          discountLimit
          quota
          quotaUsed
          totalRevenue
          appliedDiscountedVal
          usePerCustomer
          channelReq
          customerEligibility
          customerReq
          spendingExceeds
          spendingBelow
          orderSinceDate
          orderFromDate
          orderToDate
          isDisplayed
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        reviewID
        review {
          id
          accountID
          bookingID
          guestName
          guestId
          reason
          value
          userID
          orderID
          rate
          satisfaction
          thoughts
          itemReviews {
            itemID
            rate
            satisfaction
            multiLanguages
            __typename
          }
          photos
          multiLanguages
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdByID
        createdByName
        tmpField
        otp
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderStatus = /* GraphQL */ `
  query GetOrderStatus($id: ID!) {
    getOrderStatus(id: $id) {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrderStatuses = /* GraphQL */ `
  query ListOrderStatuses(
    $filter: ModelOrderStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        color
        precedence
        accessibleStatus
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderStatuses = /* GraphQL */ `
  query SyncOrderStatuses(
    $filter: ModelOrderStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        color
        precedence
        accessibleStatus
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDiscountCoupon = /* GraphQL */ `
  query GetDiscountCoupon($id: ID!) {
    getDiscountCoupon(id: $id) {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDiscountCoupons = /* GraphQL */ `
  query ListDiscountCoupons(
    $filter: ModelDiscountCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        discountPercent
        usedBy
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDiscountCoupons = /* GraphQL */ `
  query SyncDiscountCoupons(
    $filter: ModelDiscountCouponFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscountCoupons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        discountPercent
        usedBy
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        location
        deliveryFee
        concepts {
          id
          deliveryFee
          __typename
        }
        polygon
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncZones = /* GraphQL */ `
  query SyncZones(
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncZones(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        location
        deliveryFee
        concepts {
          id
          deliveryFee
          __typename
        }
        polygon
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const zoneByConceptID = /* GraphQL */ `
  query ZoneByConceptID(
    $conceptID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    zoneByConceptID(
      conceptID: $conceptID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        location
        deliveryFee
        concepts {
          id
          deliveryFee
          __typename
        }
        polygon
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const zoneByConceptIDSortedByLocationName = /* GraphQL */ `
  query ZoneByConceptIDSortedByLocationName(
    $conceptID: ID!
    $location: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    zoneByConceptIDSortedByLocationName(
      conceptID: $conceptID
      location: $location
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        location
        deliveryFee
        concepts {
          id
          deliveryFee
          __typename
        }
        polygon
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserIP = /* GraphQL */ `
  query GetUserIP($id: ID!) {
    getUserIP(id: $id) {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserIPS = /* GraphQL */ `
  query ListUserIPS(
    $filter: ModelUserIPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserIPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ip
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserIPS = /* GraphQL */ `
  query SyncUserIPS(
    $filter: ModelUserIPFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserIPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ip
        date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMainCategory = /* GraphQL */ `
  query GetMainCategory($id: ID!) {
    getMainCategory(id: $id) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMainCategories = /* GraphQL */ `
  query ListMainCategories(
    $filter: ModelMainCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        precedence
        openTime
        closeTime
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMainCategories = /* GraphQL */ `
  query SyncMainCategories(
    $filter: ModelMainCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMainCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        precedence
        openTime
        closeTime
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mainCategoryByConceptID = /* GraphQL */ `
  query MainCategoryByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMainCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mainCategoryByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        precedence
        openTime
        closeTime
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSubCategory = /* GraphQL */ `
  query GetSubCategory($id: ID!) {
    getSubCategory(id: $id) {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSubCategories = /* GraphQL */ `
  query ListSubCategories(
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conceptID
        name
        precedence
        openTime
        closeTime
        mainCategoryID
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSubCategories = /* GraphQL */ `
  query SyncSubCategories(
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        name
        precedence
        openTime
        closeTime
        mainCategoryID
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const subCategoryByConceptID = /* GraphQL */ `
  query SubCategoryByConceptID(
    $conceptID: ID!
    $precedence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoryByConceptID(
      conceptID: $conceptID
      precedence: $precedence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        name
        precedence
        openTime
        closeTime
        mainCategoryID
        guestView
        guestOrder
        staffOrder
        image
        menuPDF
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        numberOfOrders
        isActive
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChannels = /* GraphQL */ `
  query SyncChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChannels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        numberOfOrders
        isActive
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPromocode = /* GraphQL */ `
  query GetPromocode($id: ID!) {
    getPromocode(id: $id) {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPromocodes = /* GraphQL */ `
  query ListPromocodes(
    $filter: ModelPromocodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromocodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPromocodes = /* GraphQL */ `
  query SyncPromocodes(
    $filter: ModelPromocodeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPromocodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const promoCodeByName = /* GraphQL */ `
  query PromoCodeByName(
    $name: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromocodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoCodeByName(
      name: $name
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const promoCodeByDeleted = /* GraphQL */ `
  query PromoCodeByDeleted(
    $deleted: String!
    $totalRevenue: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromocodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoCodeByDeleted(
      deleted: $deleted
      totalRevenue: $totalRevenue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        redemptions
        status
        startDate
        endDate
        appliesTo
        minOrderReq
        minOrderVal
        minOrderQty
        tenderReq
        discountVal
        discountLimit
        quota
        quotaUsed
        totalRevenue
        appliedDiscountedVal
        usePerCustomer
        channelReq
        customerEligibility
        customerReq
        spendingExceeds
        spendingBelow
        orderSinceDate
        orderFromDate
        orderToDate
        isDisplayed
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPromocodeDashboard = /* GraphQL */ `
  query GetPromocodeDashboard($id: ID!) {
    getPromocodeDashboard(id: $id) {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPromocodeDashboards = /* GraphQL */ `
  query ListPromocodeDashboards(
    $filter: ModelPromocodeDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromocodeDashboards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conceptID
        totalRevenue
        totalRedemptions
        totalDiscount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPromocodeDashboards = /* GraphQL */ `
  query SyncPromocodeDashboards(
    $filter: ModelPromocodeDashboardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPromocodeDashboards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conceptID
        totalRevenue
        totalRedemptions
        totalDiscount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLoyaltyItem = /* GraphQL */ `
  query GetLoyaltyItem($id: ID!) {
    getLoyaltyItem(id: $id) {
      id
      name
      description
      startDate
      endDate
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLoyaltyItems = /* GraphQL */ `
  query ListLoyaltyItems(
    $filter: ModelLoyaltyItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoyaltyItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLoyaltyItems = /* GraphQL */ `
  query SyncLoyaltyItems(
    $filter: ModelLoyaltyItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLoyaltyItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOTP = /* GraphQL */ `
  query GetOTP($id: ID!) {
    getOTP(id: $id) {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        description
        startDate
        endDate
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptTendersId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orderType {
          items {
            id
            name
            simphonyID
            precedence
            deleted
            createdAt
            createdByID
            createdByName
            updatedAt
            _version
            _deleted
            _lastChangedAt
            conceptOrderTypeId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOTPS = /* GraphQL */ `
  query ListOTPS(
    $filter: ModelOTPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOTPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OTP
        redeemed
        deleted
        userID
        conceptID
        loyaltyItemID
        loyaltyItem {
          id
          name
          description
          startDate
          endDate
          points
          redemptionPerUser
          image
          simphonyID
          multiLanguages
          isActive
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          pictureCrop
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          spending
          prevOrderDate
          lastOrderDate
          promocodes {
            name
            promocodeId
            canBeUsed
            discountVal
            discountLimit
            isDisplayed
            __typename
          }
          mostOrderedItems
          points
          deleted
          createdAt
          createdByID
          createdByName
          cognitoUsername
          cognitoSub
          autoGeneratedPassword
          updated
          sub
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        concept {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptTendersId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          orderType {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptOrderTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          containerColor
          avgOrderCompletionEstimate
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOTPS = /* GraphQL */ `
  query SyncOTPS(
    $filter: ModelOTPFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOTPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        OTP
        redeemed
        deleted
        userID
        conceptID
        loyaltyItemID
        loyaltyItem {
          id
          name
          description
          startDate
          endDate
          points
          redemptionPerUser
          image
          simphonyID
          multiLanguages
          isActive
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          pictureCrop
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          spending
          prevOrderDate
          lastOrderDate
          promocodes {
            name
            promocodeId
            canBeUsed
            discountVal
            discountLimit
            isDisplayed
            __typename
          }
          mostOrderedItems
          points
          deleted
          createdAt
          createdByID
          createdByName
          cognitoUsername
          cognitoSub
          autoGeneratedPassword
          updated
          sub
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        concept {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptTendersId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          orderType {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptOrderTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          containerColor
          avgOrderCompletionEstimate
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ByOTP = /* GraphQL */ `
  query ByOTP(
    $OTP: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOTPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByOTP(
      OTP: $OTP
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OTP
        redeemed
        deleted
        userID
        conceptID
        loyaltyItemID
        loyaltyItem {
          id
          name
          description
          startDate
          endDate
          points
          redemptionPerUser
          image
          simphonyID
          multiLanguages
          isActive
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          pictureCrop
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          spending
          prevOrderDate
          lastOrderDate
          promocodes {
            name
            promocodeId
            canBeUsed
            discountVal
            discountLimit
            isDisplayed
            __typename
          }
          mostOrderedItems
          points
          deleted
          createdAt
          createdByID
          createdByName
          cognitoUsername
          cognitoSub
          autoGeneratedPassword
          updated
          sub
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        concept {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptTendersId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          orderType {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptOrderTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          containerColor
          avgOrderCompletionEstimate
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const otpByUserID = /* GraphQL */ `
  query OtpByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOTPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    otpByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OTP
        redeemed
        deleted
        userID
        conceptID
        loyaltyItemID
        loyaltyItem {
          id
          name
          description
          startDate
          endDate
          points
          redemptionPerUser
          image
          simphonyID
          multiLanguages
          isActive
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          accountID
          username
          preferred_username
          email
          email_verified
          phone_number
          phone_number_verified
          name
          nickname
          given_name
          middle_name
          family_name
          address
          birthdate
          gender
          locale
          picture
          pictureCrop
          website
          zoneinfo
          verification_code
          facebook
          instagram
          group
          phones
          flags
          flagsName
          interests
          interestsName
          status
          stats
          spending
          prevOrderDate
          lastOrderDate
          promocodes {
            name
            promocodeId
            canBeUsed
            discountVal
            discountLimit
            isDisplayed
            __typename
          }
          mostOrderedItems
          points
          deleted
          createdAt
          createdByID
          createdByName
          cognitoUsername
          cognitoSub
          autoGeneratedPassword
          updated
          sub
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        concept {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          tenders {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptTendersId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          orderType {
            items {
              id
              name
              simphonyID
              precedence
              deleted
              createdAt
              createdByID
              createdByName
              updatedAt
              _version
              _deleted
              _lastChangedAt
              conceptOrderTypeId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          containerColor
          avgOrderCompletionEstimate
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
