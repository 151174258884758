import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { createTransaction, updateTransaction } from "../graphql/mutations";
import { Transaction } from "../models";
import {
  CreateTransactionInput,
  ModelStringKeyConditionInput,
} from "../models/GQL_API";
import {
  CreateTransactionObj,
  TransactionFetchChartData,
  TransactionFetchStatistics,
  TransactionGetOnlineVariables,
  TransactionGetVariables,
  TransactionListingVariables,
} from "./../models/app";
import useApp from "./useApp";
// import { bookingTransactions } from "../graphql/queries";
import { transactionsByDeleted } from "../graphql/queries";
import { getDateFormatted } from "../helpers/utils";
import { HeadCell } from "../models/dataTable";
import {
  fetchTransactionsChart,
  fetchTransactionsStatistics,
} from "../services/transactionsService";
import {
  setChartData,
  setEarningAmount,
  setFilter,
  setLastIndex,
  setLinksSent,
  setListing,
  setNextToken,
  setPagination,
  setRefundAmount,
  setSelected,
  setStatus,
} from "../store/ducks/transactions";
import useConcept from "./useConcept";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();
  const { conceptsGetName } = useConcept("concepts", "concept");

  const session = useSelector((state: any) => state.app.session);

  let nextToken = useSelector((state: any) => state.transactions.nextToken);
  let lastIndex = useSelector((state: any) => state.transactions.lastIndex);
  let paginationListing = useSelector(
    (state: any) => state.transactions.pagination
  );
  let paginationFilter = useSelector((state: any) => state.transactions.filter);

  const selectedConcept = useSelector((state: any) => state.concepts.selected);
  const statusList = useSelector((state: any) => state.transactions.status);

  async function fetch(props: TransactionListingVariables) {
    const {
      searchText,
      startIndex,
      limit,
      fromDate,
      toDate,
      refund,
      status,
      conceptID,
      conceptsSelectedFilters,
    } = props;
    try {
      let requestLimit = 100000;
      let requestStartIndex = startIndex;
      const createdAtFilter: ModelStringKeyConditionInput = {};
      const filter: any = {};
      filter.and = [];
      filter.or = [];

      if (searchText && searchText.length > 0) {
        filter.or.push({ id: { contains: searchText } });
        filter.or.push({ guestPhone: { contains: searchText } });
        filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
        filter.or.push({ transactionID: { contains: searchText } });
        filter.or.push({ reference_orderID: { contains: searchText } });
      }
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      if (statusList && statusList.length > 0) {
        for (const statusItem of statusList) {
          if (statusItem.toLowerCase() === "failed") {
            filter.or.push({ status: { eq: false } });
          } else if (statusItem.toLowerCase() === "success") {
            filter.or.push({ status: { eq: true } });
          }
        }
      }

      if (typeof refund === "boolean") {
        filter.refund = { eq: refund };
      }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }
      if (
        paginationFilter &&
        paginationFilter.toString() !=
          [
            searchText,
            startIndex,
            limit,
            fromDate,
            toDate,
            refund,
            statusList,
            conceptID,
            conceptsSelectedFilters,
          ].toString()
      ) {
        dispatch(setPagination([]));
        dispatch(setNextToken(null));
        dispatch(setLastIndex(0));

        paginationListing = [];
        nextToken = null;
        lastIndex = null;
      }
      if (
        lastIndex >= startIndex &&
        lastIndex !== null &&
        paginationListing.length > 0 &&
        selectedConcept === paginationListing[0].conceptID
      ) {
        return paginationListing.slice(startIndex, startIndex + limit);
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const listing: any = await API.graphql<GraphQLQuery<Transaction>>({
        query: transactionsByDeleted,
        variables: {
          deleted: "0",
          filter,
          limit: requestLimit ? requestLimit : 50,
          sortDirection: "DESC",
          createdAt: createdAtFilter,
          startIndex: requestStartIndex ? requestStartIndex + 1 : 0,
          nextToken:
            paginationListing.length > 0 &&
            selectedConcept === paginationListing[0].conceptID
              ? nextToken
              : null,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const transactionsListing = listing.data.transactionsByDeleted.items;

      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.transactionsByDeleted.nextToken));
      dispatch(setPagination(paginationListing.concat(transactionsListing)));
      dispatch(
        setFilter([
          searchText,
          startIndex,
          limit,
          fromDate,
          toDate,
          refund,
          statusList,
          conceptID,
          conceptsSelectedFilters,
        ])
      );

      return transactionsListing;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchStatistics(params: TransactionFetchStatistics) {
    try {
      const { fromDate, toDate, conceptID, conceptsSelectedFilters } = params;

      const createdAtFilter: ModelStringKeyConditionInput = {};
      const filter: any = {};
      filter.and = [];
      filter.or = [];
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      if (fromDate && toDate) {
        const toDatePlusOneDay = new Date(toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables = {
        deleted: "0",
        filter,
        sortDirection: "DESC",
        createdAt: createdAtFilter,
      };

      return await fetchTransactionsStatistics(variables);
    } catch (err) {
      showError(err);
    }
  }

  async function fetchChartData(params: TransactionFetchChartData) {
    try {
      const { chartRange, conceptID, conceptsSelectedFilters } = params;

      const filter: any = {};
      filter.and = [];
      filter.or = [];
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      return await fetchTransactionsChart(filter, chartRange);
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: TransactionGetVariables) {
    const { id } = params;

    try {
      const single: Transaction | undefined = await DataStore.query(
        Transaction as any,
        id
      );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(params: TransactionGetOnlineVariables) {
    try {
      const { id, transactionID, bookingID } = params;

      const listing = await DataStore.query(
        Transaction as any,
        (transaction: any) => {
          transaction.deleted("eq", "0");
          if (id) transaction.id("eq", id);
          if (transactionID) transaction.transactionID("eq", transactionID);
          if (bookingID) transaction.bookingId("eq", bookingID);
          return transaction;
        },
        {
          page: 0,
          limit: 1,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function update(data: any) {
    try {
    } catch (err) {
      showError(err);
    }
  }

  async function updateOnline(params: any, session = null) {
    try {
      const transaction: any = await API.graphql<GraphQLQuery<Transaction>>({
        query: updateTransaction,
        variables: { input: params },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return transaction;
    } catch (err) {
      showError(err);
    }
  }

  async function create(data: CreateTransactionObj, isAuth = null) {
    try {
      if (!data.conceptID) throw new Error("You must select a concept first.");

      const createInput: CreateTransactionInput = {
        transactionID: data.transactionID,
        date: getDateFormatted(new Date()),
        guestName: data.guestName,
        guestPhone: data.guestPhone,
        guestID: data.guestID,
        guestGroup: data.guestGroup,
        guestsNames: data.guestsNames,
        bookingID: data.bookingID,
        amount_cents: data.amount_cents,
        status: data.status!,
        failureReason: data.failureReason,
        type: data.type,
        timeSlots: data.timeSlots,
        tables: data.tables,
        conceptID: data.conceptID,
        currency: data.currency,
        refund: data.refund!,
        ownerID: data.ownerID,
        refunded_amount_cents: data.refunded_amount_cents,
        deleted: "0",
        createdAt: new Date().toISOString(),
      };

      // await DataStore.save(new Object(createInput as any));
      const transaction = await API.graphql<GraphQLQuery<Transaction>>({
        query: createTransaction,
        variables: { input: createInput },
        authMode: isAuth
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return transaction;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: TransactionListingVariables) {
    const data = await fetch(params);

    let exportedData = [];

    for (let transaction of data!) {
      let row: any = { ...transaction };

      if (row.status && !row.refund) {
        row.status = "Success";
      } else if (row.refund) {
        row.status = "Refunded";
      } else {
        row.status = "Failed";
      }

      if (transaction.currency) {
        row.amount_cents =
          transaction.amount_cents / 100 + " " + transaction.currency;
      }

      if (transaction.conceptID) {
        row.conceptID = conceptsGetName({
          id: transaction.conceptID,
          listing: params.conceptsListing ? params.conceptsListing : [],
        });
      }

      exportedData.push(row);
    }

    return exportedData;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "transactionID",
      numeric: false,
      disablePadding: false,
      label: "Trx ID",
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "guestName",
      numeric: false,
      disablePadding: false,
      label: "Guest Name",
    },
    {
      id: "guestPhone",
      numeric: false,
      disablePadding: false,
      label: "Mobile",
    },
    {
      id: "amount_cents",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Payment Status",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "type",
    },
    {
      id: "conceptID",
      numeric: false,
      disablePadding: false,
      label: "Concept",
    },
    {
      id: "failureReason",
      numeric: false,
      disablePadding: false,
      label: "Details",
    },
  ];

  const dataCells: readonly string[] = [
    "transactionID",
    "date",
    "guestName",
    "guestPhone",
    "amount_cents",
    "status",
    "type",
    "conceptID",
    "createdAt",
  ];

  const api: any = {};

  api[`${listingName}Model`] = Transaction as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchStatistics`] = fetchStatistics;
  api[`${listingName}FetchChartData`] = fetchChartData;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}ChangeListing`] = (listing: Transaction[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeEarningAmount`] = (earningAmount: number) =>
    dispatch(setEarningAmount(earningAmount));
  api[`${listingName}ChangeRefundAmount`] = (refundAmount: number) =>
    dispatch(setRefundAmount(refundAmount));
  api[`${listingName}ChangeLinksSent`] = (linksSent: number) =>
    dispatch(setLinksSent(linksSent));
  api[`${listingName}ChangeStatus`] = (status: string) =>
    dispatch(setStatus(status));
  api[`${listingName}ChangeChartData`] = (chartData: any[]) =>
    dispatch(setChartData(chartData));

  return api;
};

export default useResource;
