import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { Languages } from "../constants/enums";
import { createChoiceGroups, updateChoiceGroups } from "../graphql/mutations";
import {
  choiceGroupsByConceptID,
  getChoiceGroups,
  listChoiceGroups,
} from "../graphql/queries";
import { onCreateChoiceGroups } from "../graphql/subscriptions";
import { extractSelectedCheckboxes } from "../helpers/utils";
import { ChoiceGroups } from "../models";
import {
  ChoiceGroupsBulkTrashVariables,
  GetVariables,
  ListingByConceptVariables,
  Option,
} from "../models/app";
import { HeadCell } from "../models/dataTable";
import {
  setAllListing,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
} from "../store/ducks/choiceGroup";
import {
  CreateChoiceGroupsInput,
  UpdateChoiceGroupsInput,
} from "./../models/GQL_API";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const language = useSelector((state: any) => state.accounts.language);

  const session = useSelector((state: any) => state.app.session);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);
  const selectedParentConcept = useSelector(
    (state: any) => state.parentconcepts.selected
  );

  const nextToken = useSelector((state: any) => state.choiceGroups.nextToken);
  const lastIndex = useSelector((state: any) => state.choiceGroups.lastIndex);
  const paginationListing = useSelector(
    (state: any) => state.choiceGroups.pagination
  );
  const choiceGroupsAllListing = useSelector(
    (state: any) => state.choiceGroups.allListing
  );

  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
      };

      if (searchText.length > 0) {
        const listing: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
          query: listChoiceGroups,
          variables: { filter, limit: 1000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        dispatch(setLastIndex(0));
        dispatch(setNextToken(null));
        dispatch(setPagination([]));

        return listing.data.listChoiceGroups.items;
      }
      if (
        paginationListing.length > 0 &&
        selectedConcept === paginationListing[0]?.conceptID &&
        ((lastIndex >= startIndex &&
          lastIndex !== null &&
          limit < paginationListing.length) ||
          !nextToken)
      ) {
        return paginationListing.slice(startIndex, startIndex + limit);
      }
      const listing: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: listChoiceGroups,
        variables: {
          filter,
          limit: limit ?? 1000,
          nextToken:
            paginationListing.length > 0 &&
            selectedConcept === paginationListing[0]?.conceptID
              ? nextToken
              : undefined,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.listChoiceGroups.nextToken));
      let list =
        selectedConcept === paginationListing[0]?.conceptID
          ? paginationListing.concat(listing.data.listChoiceGroups.items)
          : listing.data.listChoiceGroups.items;
      dispatch(setPagination(list));

      //console.log(list)

      return list.slice(startIndex, startIndex + limit);
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchAll(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
        deleted: { eq: "0" },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        choiceGroupsAllListing.length === 0 ||
        conceptID !== choiceGroupsAllListing[0].conceptID
      ) {
        const listing: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
          query: listChoiceGroups,
          variables: { filter, limit: limit ?? 1000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        return listing.data.listChoiceGroups.items;
      } else {
        return choiceGroupsAllListing;
      }
    } catch (err: Error | any) {
      showError(err.message || err);
      return [];
    }
  }
  async function fetchChoiceGroupsByConceptID(
    params: ListingByConceptVariables
  ) {
    try {
      const { conceptID, searchText, limit, forceRefresh } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        choiceGroupsAllListing.length === 0 ||
        conceptID !== choiceGroupsAllListing[0].conceptID ||
        forceRefresh
      ) {
        const listing: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
          query: choiceGroupsByConceptID,
          variables: { filter, conceptID: conceptID, limit: limit ?? 10000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        let nextToken = listing.data.choiceGroupsByConceptID.nextToken;
        let allItems = listing.data.choiceGroupsByConceptID.items;
        // console.log({ listing });

        while (nextToken && nextToken.length > 0) {
          const newListing: any = await API.graphql<GraphQLQuery<ChoiceGroups>>(
            {
              query: choiceGroupsByConceptID,
              variables: {
                filter,
                conceptID: conceptID,
                limit: limit ?? 10000,
                nextToken,
              },
              authMode: session
                ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
                : GRAPHQL_AUTH_MODE.AWS_IAM,
            }
          );

          allItems = allItems.concat(
            newListing.data.choiceGroupsByConceptID.items
          );
          nextToken = newListing.data.choiceGroupsByConceptID.nextToken;
        }
        return allItems.sort((a: any, b: any) => {
          return b.precedence - a.precedence;
        });
      } else {
        return choiceGroupsAllListing ?? [];
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }
  async function get(params: any) {
    const { id } = params;

    try {
      const choiceGroup: any = await API.graphql({
        query: getChoiceGroups,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return choiceGroup.data.getChoiceGroups;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    if (!selectedParentConcept)
      throw new Error("You must select a parent concept first");
    if (!selectedConcept) throw new Error("You must select a concept first.");

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    multiLanguages = JSON.stringify(multiLanguages);

    try {
      if (!data.name) throw new Error("Name is required");
      const choices: string[] = extractSelectedCheckboxes("choices_", data);

      const createInput: CreateChoiceGroupsInput = {
        conceptID: selectedConcept,
        parentConceptID: selectedParentConcept,
        name: data.name,
        required: data.required !== undefined ? data.required : false,
        maxNumberOfChoices: data.maxNumberOfChoices
          ? parseInt(data.maxNumberOfChoices)
          : 0,
        minNumberOfChoices: data.minNumberOfChoices
          ? parseInt(data.minNumberOfChoices)
          : 0,
        precedence: data.precedence ? data.precedence : "0",
        choices: choices ? choices : [],
        multiLanguages: multiLanguages,

        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const ChoiceGroups: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: createChoiceGroups,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing = [
        ...choiceGroupsAllListing,
        ChoiceGroups.data.createChoiceGroups,
      ];
      dispatch(setAllListing(newListing));

      showConfirm(`New ${singleName} has been created successfully`);

      return ChoiceGroups;
    } catch (err:any) {
      console.log(err);
      showError(err?.message ?? "Something went wrong.");
    }
  }

  async function update(params: any, session: any) {
    try {
      const { data } = params;
      let original = await get(params);

      let choices: any = new Set();
      const updatedChoices: string[] = extractSelectedCheckboxes(
        "choices_",
        data
      );
      if (updatedChoices && updatedChoices.length > 0) {
        for (let choice of updatedChoices) {
          choices.add(choice);
        }
        choices = Array.from(choices);
      } else {
        choices = Array.from(choices);
      }

      let multiLanguages: any = [];
      if (original.multiLanguages) {
        multiLanguages = new Map(JSON.parse(original.multiLanguages));
        if (data.name) {
          multiLanguages.set(`${language}-name`, data.name);
        }
        multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
      } else {
        if (data.name) {
          multiLanguages.push([`${language}-name`, data.name]);
        }
        multiLanguages = JSON.stringify(multiLanguages);
      }
      let newChoices: any = [];
      if (data.choice && data.action === "add") {
        let choices = [...original.choices, data.choice];
        let choicesSet = new Set(choices);
        newChoices = Array.from(choicesSet);
      }
      if (data.choice && data.action === "remove") {
        let choices = [...original.choices];
        let newChoices = choices.filter((id: any) => {
          return id !== data.choice;
        });
        // console.log(newChoices)
      }

      const updateInput: UpdateChoiceGroupsInput = {
        id: original.id,
        name:
          data.name && language === Languages.ENGLISH
            ? data.name.toLowerCase()
            : original!.name,
        required:
          data.required !== undefined ? data.required : original!.required,
        maxNumberOfChoices: data.maxNumberOfChoices
          ? parseInt(data.maxNumberOfChoices)
          : original!.maxNumberOfChoices,
        minNumberOfChoices: data.minNumberOfChoices
          ? parseInt(data.minNumberOfChoices)
          : original!.minNumberOfChoices,
        precedence: data.precedence ? data.precedence : original!.precedence,
        choices: data.choice
          ? newChoices
          : choices
          ? choices
          : original!.choices,
        multiLanguages: multiLanguages,

        _version: original._version,
      };

      const ChoiceGroups: any = await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: updateChoiceGroups,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);

      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing: any = [];
      choiceGroupsAllListing.map((item: any) => {
        if (item.id === ChoiceGroups.data.updateChoiceGroups.id) {
          newListing.push(ChoiceGroups.data.updateChoiceGroups);
        } else {
          newListing.push(item);
        }
      });
      dispatch(setAllListing(newListing));

      return ChoiceGroups.data.updateChoiceGroups;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      const updateInput: UpdateChoiceGroupsInput = {
        id: original.id,
        deleted: "1",

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<ChoiceGroups>>({
        query: updateChoiceGroups,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ChoiceGroupsBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  function options(listing: ChoiceGroups[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = ChoiceGroups as any;
  api[`${listingName}CreateSubscription`] = onCreateChoiceGroups;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}FetchByConceptID`] = fetchChoiceGroupsByConceptID;

  api[`${listingName}ChangeListing`] = (listing: ChoiceGroups[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: ChoiceGroups[]) =>
    dispatch(setAllListing(listing));

  return api;
};

export default useResource;
