import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { Languages } from "../constants/enums";
import { createCategory, updateCategory } from "../graphql/mutations";
import {
  categoryByConceptID,
  getCategory,
  listCategories,
} from "../graphql/queries";
import { onCreateCategory } from "../graphql/subscriptions";
import { Category } from "../models";
import { ListingByConceptVariables, Option } from "../models/app";
import { HeadCell } from "../models/dataTable";
import {
  setAllListing,
  setFilters,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
  setSelectedFilters,
} from "../store/ducks/category";
import { CreateCategoryInput, UpdateCategoryInput } from "./../models/GQL_API";
import useApp from "./useApp";

const useCategory = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const language = useSelector((state: any) => state.accounts.language);

  const session = useSelector((state: any) => state.app.session);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);
  const selectedParentConcept = useSelector(
    (state: any) => state.parentconcepts.selected
  );
  const conceptsSelectedFilters = useSelector(
    (state: any) => state.concepts.selectedFilters
  );

  const categoriesListing = useSelector(
    (state: any) => state.categories.allListing
  );
  const categoriesListingPag = useSelector(
    (state: any) => state.categories.listing
  );
  const nextToken = useSelector((state: any) => state.categories.nextToken);

  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      // console.log({ params });

      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      const categoriesList: any = await API.graphql<GraphQLQuery<Category>>({
        query: listCategories,
        variables: { filter, limit, nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      // console.log({ categoriesList });
      const currentNextToken = categoriesList.data.listCategories.nextToken;
      const responseListing = categoriesList.data.listCategories.items;

      let listing = [...categoriesListingPag, ...responseListing];

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      filter.or = [];
      filter.and = [];

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      // Concepts Filter
      if (conceptsSelectedFilters && conceptsSelectedFilters.length > 0) {
        let or = [];
        for (let concept of conceptsSelectedFilters) {
          or.push({ conceptID: { eq: concept.id } });
        }
        filter.and.push({ or: or });
      } else {
        filter.conceptID = { eq: conceptID ? conceptID : selectedConcept };
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      // use state in case of using same conceptID
      if (
        categoriesListing.length > 0 &&
        conceptsSelectedFilters.length === 0 &&
        conceptID === categoriesListing[0].conceptID
      ) {
        return categoriesListing;
      }

      const listing: any = await API.graphql<GraphQLQuery<Category>>({
        query: listCategories,
        variables: { filter, limit: limit ? 1000 : limit },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const listSorted = listing.data.listCategories.items.sort(
        (a: any, b: any) => {
          return a.precedence - b.precedence;
        }
      );

      dispatch(setListing(listSorted));
      return listSorted;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchCategoryByConceptID(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, forceRefresh } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        categoriesListing.length === 0 ||
        conceptID !== categoriesListing[0].conceptID ||
        forceRefresh
      ) {
        const listing: any = await API.graphql<GraphQLQuery<Category>>({
          query: categoryByConceptID,
          variables: { filter, conceptID: conceptID, limit: limit ?? 10000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        let nextToken = listing.data.categoryByConceptID.nextToken;
        let allItems = listing.data.categoryByConceptID.items;

        while (nextToken && nextToken.length > 0) {
          const newListing: any = await API.graphql<GraphQLQuery<Category>>({
            query: categoryByConceptID,
            variables: {
              filter,
              conceptID: conceptID,
              limit: limit ?? 10000,
              nextToken,
            },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });

          allItems = allItems.concat(newListing.data.categoryByConceptID.items);
          nextToken = newListing.data.categoryByConceptID.nextToken;
        }
        return allItems.sort((a: any, b: any) => {
          return b.precedence - a.precedence;
        });
      } else {
        return categoriesListing;
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const category: any = await API.graphql({
        query: getCategory,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return category.data.getCategory;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: any) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Category) => model.id === id);
      return model ? model.name : "";
    }

    return "";
  };

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    if (!selectedParentConcept)
      throw new Error("You must select a parent concept first");
    if (!selectedConcept) throw new Error("You must select a concept first.");

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    if (data.image)
      multiLanguages.push([`${Languages.ENGLISH}-image`, data.image]);
    if (data.darkImage)
      multiLanguages.push([`${Languages.ENGLISH}-darkImage`, data.darkImage]);

    multiLanguages = JSON.stringify(multiLanguages);

    try {
      const createInput: CreateCategoryInput = {
        name: data.name.toLowerCase(),
        image: data.image
          ? data.image.fileUrl
            ? data.image.fileUrl
            : data.image
          : "",
        darkImage: data.darkImage
          ? data.darkImage.fileUrl
            ? data.darkImage.fileUrl
            : data.darkImage
          : "",
        preparationAreaID: data.preparationAreaID ? data.preparationAreaID : "",
        subCategory: data.subCategory ? data.subCategory : null,
        guestView: data.guestView ? data.guestView : false,
        guestOrder: data.guestOrder ? data.guestOrder : false,
        staffOrder: data.staffOrder ? data.staffOrder : false,
        precedence: data.precedence ? data.precedence : "0",
        conceptID: selectedConcept,
        parentConceptID: selectedParentConcept,
        multiLanguages: multiLanguages,
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const Category: any = await API.graphql<GraphQLQuery<Category>>({
        query: createCategory,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing = [...categoriesListing, Category.data.createCategory];
      dispatch(setAllListing(newListing));

      return Category;
    } catch (err: any) {
      console.log(err);
      showError(err?.message ?? "Something went wrong.");
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      let multiLanguages: any = [];
      if (original.multiLanguages) {
        multiLanguages = new Map(JSON.parse(original.multiLanguages));
        if (data.name) {
          multiLanguages.set(`${language}-name`, data.name);
        }
        if (data.image) {
          multiLanguages.set(`${language}-image`, data.image);
        }
        if (data.darkImage) {
          multiLanguages.set(`${language}-darkImage`, data.darkImage);
        }
        multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
      } else {
        if (data.name) {
          multiLanguages.push([`${language}-name`, data.name]);
        }
        if (data.image) {
          multiLanguages.push([`${language}-image`, data.image]);
        }
        if (data.darkImage) {
          multiLanguages.push([`${language}-darkImage`, data.darkImage]);
        }
        multiLanguages = JSON.stringify(multiLanguages);
      }

      const updateInput: UpdateCategoryInput = {
        id: original.id,
        name:
          data.name && language === Languages.ENGLISH
            ? data.name.toLowerCase()
            : original!.name,
        image:
          data.image && language === Languages.ENGLISH
            ? data.image.fileUrl
              ? data.image.fileUrl
              : data.image
            : original!.image,
        darkImage:
          data.darkImage && language === Languages.ENGLISH
            ? data.darkImage.fileUrl
              ? data.darkImage.fileUrl
              : data.darkImage
            : original!.darkImage,
        precedence: data.precedence ? data.precedence : original.precedence,
        subCategory: data.subCategory ? data.subCategory : original.subCategory,
        guestView: data.guestView,
        guestOrder: data.guestOrder,
        staffOrder: data.staffOrder,
        multiLanguages: multiLanguages,
        preparationAreaID: data.preparationAreaID
          ? data.preparationAreaID
          : original.preparationAreaID,
        _version: original._version,
      };

      const Category: any = await API.graphql<GraphQLQuery<Category>>({
        query: updateCategory,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing: any = [];
      categoriesListing.map((item: any) => {
        if (item.id === Category.data.updateCategory.id) {
          newListing.push(Category.data.updateCategory);
        } else {
          newListing.push(item);
        }
      });
      dispatch(setAllListing(newListing));

      return Category.data.updateCategory;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: Category[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  async function trash(params: any) {
    let original = await get(params);
    const updateInput: UpdateCategoryInput = {
      id: original.id,
      deleted: "1",
      _version: original._version,
    };

    await API.graphql<GraphQLQuery<Category>>({
      query: updateCategory,
      variables: { input: updateInput },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    showConfirm(`${singleName} has been moved to trash successfully`);
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = ["name", "precedence"];

  const api: any = {};

  api[`${listingName}Model`] = Category as any;
  api[`${listingName}CreateSubscription`] = onCreateCategory;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}FetchByConceptID`] = fetchCategoryByConceptID;
  api[`${listingName}ChangeListing`] = (listing: Category[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeAllListing`] = (listing: Category[]) => {
    dispatch(setAllListing(listing));
    dispatch(setFilters(listing?.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = categoriesListingPag;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useCategory;
